export enum RedeemErrors {
  NFC_CODE_NOT_FOUND = 'NFC_CODE_NOT_FOUND',
  TOKEN_NOT_CLAIMABLE = 'TOKEN_NOT_CLAIMABLE',
}

export const RedeemErrorsMessage = {
  NFC_CODE_NOT_FOUND: 'NFC not found. Please make sure you scan the authorized NFC and insert valid code.',
  TOKEN_NOT_CLAIMABLE: 'This token is not claimable as it was claimed before, please retry with another one.',
  UNAUTHORIZED: '401 unauthorized issue. Please disconnect wallet and connect again.',
  UNEXPECTED_ERROR: 'Unexpected error occur. Please retry or contact support.',
  TRANSACTION_SIGNING_REJECTED: 'Transaction signing rejected. Please try again.',
}

export interface ErrorStructure {
  errorCode: RedeemErrors
  errorMessage: string
}
