import { useMutation, UseMutationOptions } from '@tanstack/react-query'
// eslint-disable-next-line no-restricted-imports
import { Contract } from 'ethers'
import useParticle from 'hooks/useParticle'

import abi from '../../../abis/partner-registry-abi.json'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

interface UseCreateEventInRegistryProps {
  startTime: number
  endTime: number
  rewardPerFlexer: string
  tokenRewardPool: string
  isPOAPEnabled: boolean
}

export const useCreateEventInRegistry = (options?: UseMutationOptions<string, any, UseCreateEventInRegistryProps>) => {
  // const { library } = useActiveWeb3React()
  const { provider } = useParticle()

  return useMutation<any, any, UseCreateEventInRegistryProps>(
    async ({ startTime, endTime, rewardPerFlexer, tokenRewardPool, isPOAPEnabled }) => {
      if (!provider) {
        throw new Error('NOT_CONNECTED_TO_METAMASK')
      }

      const contract = new Contract(process.env.REACT_APP_PARTNER_REGISTRY_ADDRESS as string, abi, provider.getSigner())

      const tx = await contract.createEvent(startTime, endTime, rewardPerFlexer, tokenRewardPool, isPOAPEnabled)

      const receipt = await tx.wait()

      const EVENT_CREATED_INDEX = 0
      const EVENT_ID_ARGS_INDEX = 1

      // To be checked later / verify it is needed
      // return receipt.events[EVENT_CREATED_INDEX].args[EVENT_ID_ARGS_INDEX].toString()
      return receipt.events[EVENT_CREATED_INDEX]
    },
    options
  )
}
