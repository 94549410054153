import { PAYMENT_METHODS, TPaymentMethod } from 'constants/paymentMethod'
import React, { useState } from 'react'
import styled from 'styled-components/macro'

import IconClose from '../../../assets/images/svg/icon-close.svg'
import { SalePeriodType } from '../../../entities/Sale'
import { MintingSaleInformation } from '../../../entities/SaleInformation'
import IconPortal from '../../IconPortal'
import Loader from '../../Loader'
import PaymentBox, { DivNumber, LabelName, SpanNumber } from '../../PaymentBox'
import Modal from '../index'

interface ModalConfirmMintProps {
  isOpen: boolean
  onDismiss: () => void
  handleMint: () => void
  handleMintWithCreditCardPayment: () => void
  quantity: number
  activePeriod: SalePeriodType
  payWithMatic: boolean
  setPayWithMatic: (payWithMatic: boolean) => void
  mintingSaleInformation: MintingSaleInformation
  updateMintingSaleInformation: () => void
}
const ModalConfirmMint = ({
  isOpen,
  onDismiss,
  handleMint,
  handleMintWithCreditCardPayment,
  quantity,
  activePeriod,
  setPayWithMatic,
  payWithMatic,
  mintingSaleInformation,
  updateMintingSaleInformation,
}: ModalConfirmMintProps) => {
  const [paymentMethod, setPaymentMethod] = useState<TPaymentMethod>(PAYMENT_METHODS.CRYPTO)

  const handleMinting = () => {
    handleMint()
    onDismiss()
  }

  const handleMintingWithCreditCardPayment = () => {
    handleMintWithCreditCardPayment()
    onDismiss()
  }

  return activePeriod ? (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxWidth={450}>
      <ModalWrapper>
        <ModalHeader>
          <Heading>Mint your NFTs</Heading>
          <span onClick={onDismiss} style={{ cursor: 'pointer' }}>
            <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
          </span>
        </ModalHeader>
        <ModalContent>
          <ItemsAvailable>
            <h5>ITEMS AVAILABLE</h5>
            <p>
              {activePeriod.maxAllocation - mintingSaleInformation?.totalMintedInPeriod}/{activePeriod.maxAllocation}
            </p>
          </ItemsAvailable>
          <PaymentBox
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            saleInformation={mintingSaleInformation}
            payWithMatic={payWithMatic}
            setPayWithMatic={setPayWithMatic}
            quantity={quantity}
            handleBuying={paymentMethod === PAYMENT_METHODS.CRYPTO ? handleMinting : handleMintingWithCreditCardPayment}
            updateSaleInformation={updateMintingSaleInformation}
            saleAddress={activePeriod.saleAddress}
          >
            <LabelName>Quantity</LabelName>
            <DivNumber>
              <SpanNumber>{quantity}</SpanNumber>
              NFTs
            </DivNumber>
          </PaymentBox>
        </ModalContent>
      </ModalWrapper>
    </Modal>
  ) : (
    <Loader size={'38px'} />
  )
}

const ItemsAvailable = styled.div`
  margin-bottom: 24px;
  margin-top: 36px;
  h5 {
    margin: 0 0 6px 0;
    font-weight: 600;
    color: ${({ theme }) => theme.secondary6};
    font-size: ${({ theme }) => theme.fontSizeText3};
    line-height: 1.3;
  }
  p {
    margin: 0;
    color: ${({ theme }) => theme.primary5};
    font-size: ${({ theme }) => theme.fontSizeText1};
    line-height: 1.3;
  }
`
const ModalContent = styled.div`
  .select-cs {
    &__menu {
      width: 150px;
      right: 0 !important;
    }
  }
`
const ModalWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.secondary1};
  padding: 32px;
`
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Heading = styled.h3`
  margin: 0;
  font-style: ${({ theme }) => theme.display3};
  line-height: 1.333334;
`

export default ModalConfirmMint
