import { ChainIds } from './chains'

interface BaseChainInfo {
  readonly explorer: string
  readonly label: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
}

export type ChainInfoMap = { readonly [chainId: number]: BaseChainInfo } & {
  readonly [chainId in ChainIds]: BaseChainInfo
}

export const CHAIN_INFO: ChainInfoMap = {
  [ChainIds.MAINNET]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  },
  [ChainIds.RINKEBY]: {
    explorer: 'https://rinkeby.etherscan.io/',
    label: 'Rinkeby',
    nativeCurrency: { name: 'Rinkeby Ether', symbol: 'rETH', decimals: 18 },
  },
  [ChainIds.ROPSTEN]: {
    explorer: 'https://ropsten.etherscan.io/',
    label: 'Ropsten',
    nativeCurrency: { name: 'Ropsten Ether', symbol: 'ropETH', decimals: 18 },
  },
  [ChainIds.KOVAN]: {
    explorer: 'https://kovan.etherscan.io/',
    label: 'Kovan',
    nativeCurrency: { name: 'Kovan Ether', symbol: 'kovETH', decimals: 18 },
  },
  [ChainIds.GOERLI]: {
    explorer: 'https://goerli.etherscan.io/',
    label: 'Görli',
    nativeCurrency: { name: 'Görli Ether', symbol: 'görETH', decimals: 18 },
  },
  [ChainIds.POLYGON]: {
    explorer: 'https://polygonscan.com/',
    label: 'Polygon',
    nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
  },
  [ChainIds.POLYGON_MUMBAI]: {
    explorer: 'https://mumbai.polygonscan.com/',
    label: 'Polygon Mumbai',
    nativeCurrency: { name: 'Polygon Mumbai Matic', symbol: 'mMATIC', decimals: 18 },
  },
  [ChainIds.AMOY]: {
    explorer: 'https://amoy.polygonscan.com/',
    label: 'Amoy',
    nativeCurrency: { name: 'Amoy Matic', symbol: 'MATIC', decimals: 18 },
  },
}
