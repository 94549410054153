import { useMutation, UseMutationOptions } from '@tanstack/react-query'
// eslint-disable-next-line no-restricted-imports
import { Contract } from 'ethers'
import useParticle from 'hooks/useParticle'

import abi from '../../../abis/partner-registry-abi.json'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

interface UseRemoveAllowedCollectionFromEventInRegistry {
  eventId: string
  collectionAddresses: string[]
}

export const useRemoveAllowedCollectionFromEventInRegistry = (
  options?: UseMutationOptions<string, any, UseRemoveAllowedCollectionFromEventInRegistry>
) => {
  // const { library } = useActiveWeb3React()
  const { provider } = useParticle()

  return useMutation<any, any, UseRemoveAllowedCollectionFromEventInRegistry>(
    async ({ eventId, collectionAddresses }) => {
      if (!provider) {
        throw new Error('NOT_CONNECTED_TO_METAMASK')
      }

      const contract = new Contract(process.env.REACT_APP_PARTNER_REGISTRY_ADDRESS as string, abi, provider.getSigner())

      const tx = await contract.removeAllowedCollections(Number(eventId), collectionAddresses)

      const receipt = await tx.wait()

      return receipt
    },
    options
  )
}
