import { Contract } from '@ethersproject/contracts'
import Avatar from 'assets/images/avatar.svg'
import bannerProfileImage from 'assets/images/banner.png'
import IconInstagram from 'assets/images/svg/icon-instagram2.svg'
import IconTwitter from 'assets/images/svg/icon-twitter-fill.svg'
import axios from 'axios'
import { ButtonSecondary } from 'components/Button'
import { useApi } from 'hooks/useApi'
import useDocumentTitle from 'hooks/useDocumentTitle'
import useParticle from 'hooks/useParticle'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useGetProfile } from 'state/profile/hooks'
import styled, { css } from 'styled-components/macro'
import { checkUrl, shortenAddress } from 'utils'

import IconWebsiteImg from '../../../assets/images/svg/icon-website-gray.svg'
import Modal from '../../../components/Modal'
import ModalFollowers from '../../../components/Modal/ModalFollowers'
import MoreFnc from '../../../components/MoreFnc'
import TruncateText from '../../../components/TruncateText'
import { ProfileType } from '../../../entities/Profile'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import { ClaimNFTButton } from '../../../modules/Assets/components/ClaimNFTButton/ClaimNFTButton'
import { updateFollow } from '../../../services'
import { getFollowers, getFollowing } from '../../../services'
import { useModalFollowers, useModalFollowing, useModalOpen } from '../../../state/application/hooks'
import { ApplicationModal } from '../../../state/application/reducer'

const gutterWidth = 20
const sizeAvatar = 156

export interface FollowersResponse {
  count: number
  users: ProfileType[]
  isAccountFollower: boolean
}

type SwapData = {
  balance721: number
  balance1155: number
  erc721?: Contract
  erc1155?: Contract
  isSwapping: boolean
}

const Header = () => {
  // const { account, library } = useActiveWeb3React()
  const { account, provider } = useParticle()

  const history = useHistory()
  const { pathname } = history.location
  const userProfileSelf = useGetProfile()

  const [userProfile, setUserProfile] = useState<any>(userProfileSelf)
  const { address: addressParam } = useParams<{ address: string }>()
  const [isOwnProfile, setIsOwnProfile] = useState<boolean>(false)
  const [followers, setFollowers] = useState<FollowersResponse>()
  const [following, setFollowing] = useState<FollowersResponse>()

  const toggleModalFollowers = useModalFollowers()
  const toggleModalFollowing = useModalFollowing()
  const modalFollowersOpen = useModalOpen(ApplicationModal.FOLLOWERS_POPUP)
  const modalFollowingOpen = useModalOpen(ApplicationModal.FOLLOWING_POPUP)

  //Token swapping
  const [swapModal, setSwapModal] = useState<boolean>(false)
  const [swapData, setSwapData] = useState<SwapData>({ balance721: 0, balance1155: 0, isSwapping: false })

  const { post, get } = useApi()

  useEffect(() => {
    if (!account) {
      return
    }

    getFollowers(addressParam || account, account).then((res) => {
      if (res?.status === 200) {
        const { data } = res
        setFollowers(data)
      }
    })

    getFollowing(addressParam || account, account).then((res) => {
      if (res?.status === 200) {
        const { data } = res
        setFollowing(data)
      }
    })
  }, [addressParam, account, modalFollowersOpen, modalFollowingOpen])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get('code')
    if (code) {
      get(
        {
          callback: (res) => {
            console.log({ res })
            updateUserProfile(false)
          },
          errorCallback: (err) => {
            console.log({ err })
          },
          url: `${process.env.REACT_APP_SERVER_URL}kyc/auth/token/${code}`,
        },
        true
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const clickOpenPopupFollower = () => {
    toggleModalFollowers()
  }
  const clickOpenPopupFollowing = () => {
    toggleModalFollowing()
  }
  const gotoEdit = () => {
    history.push('/edit-profile')
  }
  const gotoKYC = () => {
    const url = process.env.REACT_APP_FRACTAL_APP_URL

    const redirect_uri = process.env.REACT_APP_FRACTAL_REDIRECT_URL
    const clientId = process.env.REACT_APP_CLIENT_ID
    const response_type = 'code'
    const scope =
      'contact%3Aread%20verification.basic%3Aread%20verification.basic.details%3Aread%20verification.liveness%3Aread%20verification.liveness.details%3Aread%20verification.uniq%3Aread%20verification.uniq.details%3Aread%20verification.wallet%3Aread%20verification.wallet.details%3Aread'

    const urlFull = `${url}?client_id=${clientId}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}&ensure_wallet=${account}`
    window.open(urlFull)
  }

  const handleClickFollow = () => {
    if (!account) {
      return
    }
    ;(async () => {
      const response = await updateFollow(addressParam, account, post)
      if (response?.status === 200) {
        const { followers, message } = response.data
        const isFollowed = message === 'Follower success'
        setUserProfile((prevUserProfile: any) => {
          return {
            ...prevUserProfile,
            followers,
            isFollowed,
          }
        })
      }
    })()
  }

  const updateUserProfile = async (isUnmounted: boolean) => {
    await axios
      .get(process.env.REACT_APP_SERVER_URL + `users/detail/${addressParam ?? account}/${account}`)
      .then((res) => {
        const data = res.data
        if (isUnmounted) return
        setUserProfile(data)
      })
      .catch((err) => {
        console.log('error', err)
      })
  }

  useEffect(() => {
    let isUnmounted = false
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get('code')
    ;(async () => {
      if (!code) {
        await updateUserProfile(isUnmounted)
      }
    })()
    return () => {
      isUnmounted = true
    }
  }, [addressParam, account])

  useEffect(() => {
    if (addressParam) setIsOwnProfile(account === addressParam.toString().trim())
    else setIsOwnProfile(true)
  }, [account, addressParam])
  useDocumentTitle(`${addressParam ? userProfile?.name || 'User profile' : 'Your profile'}  | Another-1 Launchpad`)

  // const formatKYCStatus = (kycStatus: string | null) => {
  //   switch (kycStatus) {
  //     case null:
  //     case 'NOT_STARTED':
  //     default:
  //       return 'KYC not started'
  //     case 'PENDING':
  //       return 'KYC pending'
  //     case 'APPROVED':
  //       return 'KYC approved'
  //     case 'REJECTED':
  //       return 'KYC rejected'
  //   }
  // }

  return (
    <Section>
      <Banner>
        <BannerContainer>
          {/*<CoverImage img={(userProfile && userProfile.bgImageUrl) || bannerProfileImage} />*/}
          <CoverImg src={(userProfile && userProfile.bgImageUrl) || bannerProfileImage} alt={'cover_profile'} />
        </BannerContainer>
      </Banner>

      <DivContainer>
        <Cover className={'colorclassname'}>
          <AvatarBox>
            <ProfileImage>
              <img src={(userProfile && userProfile.imageUrl) || Avatar} alt="Profile" />
            </ProfileImage>
            {isOwnProfile ? (
              <>
                <ClaimButtonWrapper>
                  <ClaimNFTButton />
                </ClaimButtonWrapper>
                <EditButton onClick={gotoEdit}>Edit Profile</EditButton>
                {/* <EditButton
                  onClick={gotoKYC}
                  disabled={userProfile.kycStatus != null && userProfile.kycStatus != 'NOT_STARTED'}
                >
                  {formatKYCStatus(userProfile.kycStatus)}
                </EditButton> */}
              </>
            ) : (
              account &&
              userProfile?.numberCreator > 0 && (
                <CtaFollow onClick={() => handleClickFollow()} followed={userProfile.isFollowed}>
                  {!userProfile.isFollowed ? 'Follow' : 'Unfollow'}
                </CtaFollow>
              )
            )}
          </AvatarBox>

          <InfoBox>
            <BoxHeading>
              <h1>
                {(userProfile && userProfile.name) ||
                  (userProfile.address && shortenAddress(userProfile.address)) ||
                  'unnamed'}
              </h1>
              <div>{userProfile && <TruncateText text={userProfile.description} />}</div>
            </BoxHeading>

            <WeAreSocialBox>
              {(userProfile?.instagram || userProfile?.twitter || userProfile?.websiteUrl) && (
                <SocialLinkBox>
                  {userProfile?.websiteUrl && (
                    <a href={`${checkUrl(userProfile?.websiteUrl)}`} target="_blank" rel="noreferrer">
                      <img src={IconWebsiteImg} alt="website" />
                    </a>
                  )}
                  {userProfile?.instagram && (
                    <a href={`${checkUrl(userProfile?.instagram)}`} target="_blank" rel="noreferrer">
                      <img src={IconInstagram} alt="Icon Instagram" />
                    </a>
                  )}
                  {userProfile?.twitter && (
                    <a href={`${checkUrl(userProfile?.twitter)}`} target="_blank" rel="noreferrer">
                      <img src={IconTwitter} alt="Icon Twitter" />
                    </a>
                  )}
                </SocialLinkBox>
              )}

              <OtherLinkBox>
                {pathname.indexOf('/profile') === 0 ? <></> : <MoreFnc userAddress={userProfile.address} />}
              </OtherLinkBox>
            </WeAreSocialBox>
          </InfoBox>

          <FollowsBox>
            <FollowsBoxContent onClick={clickOpenPopupFollowing}>
              <TextFollow>Following</TextFollow>
              <TrafficFollowUp>{(userProfile.following && userProfile.following) || 0}</TrafficFollowUp>
            </FollowsBoxContent>
            <FollowsBoxContent onClick={clickOpenPopupFollower}>
              <TextFollow>Followers</TextFollow>
              <TrafficFollowUp>{(userProfile.followers && userProfile.followers) || 0}</TrafficFollowUp>
            </FollowsBoxContent>
          </FollowsBox>
        </Cover>
      </DivContainer>
      <ModalFollowers
        isOpen={modalFollowersOpen}
        onDismiss={toggleModalFollowers}
        title="Followers"
        users={followers?.users}
      />
      <ModalFollowers
        isOpen={modalFollowingOpen}
        onDismiss={toggleModalFollowing}
        title="Following"
        users={following?.users}
      />

      <Modal
        isOpen={swapModal}
        onDismiss={() => {
          setSwapModal(false)
        }}
      >
        <div style={{ backgroundColor: 'grey', color: 'black', padding: '15px', borderRadius: '10px' }}>
          <h3>Swap your tokens here </h3>

          <div className="container row">
            <div className="col">
              <p>Your ERC721: {swapData.balance721}</p>
            </div>
            <div className="col">
              <p>Your ERC1155: {swapData.balance1155}</p>
            </div>
          </div>
        </div>
      </Modal>
    </Section>
  )
}

const Section = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
`
const DivContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
`
const Cover = styled.div`
  display: flex;
  margin: -${sizeAvatar / 2}px -${gutterWidth}px 0;
  padding: 0 160px;
  position: relative;
  z-index: 2;
  @media (max-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    display: block;
  }
`

const Banner = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
`
const BannerContainer = styled.div`
  position: relative;
  height: 300px;
  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(179.33deg, rgba(11, 13, 15, 0) 0.58%, #000000 78.47%);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    z-index: 2;
  }
`
const CoverImg = styled.img`
  position: absolute;
  inset: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
`
const AvatarBox = styled.div`
  max-width: ${sizeAvatar + gutterWidth * 2}px;
  flex: 0 0 ${sizeAvatar + gutterWidth * 2}px;
  padding-left: ${gutterWidth}px;
  padding-right: ${gutterWidth}px;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const ProfileImage = styled.div`
  position: relative;
  padding-bottom: 100%;

  img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const EditButton = styled(ButtonSecondary)`
  margin-top: 24px;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: ${({ theme }) => theme.primary10};
  padding: 10px 24px;
  gap: 8px;
`
const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-left: ${gutterWidth}px;
  padding-right: ${gutterWidth}px;

  h1 {
    font-family: 'Din Pro Regular', sans-serif;
    font-size: 80px;
    line-height: 0.7;
    letter-spacing: -0.015em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.primary10};
    padding-bottom: 8px;
    margin: 0 0 24px;
  }

  p {
    font-size: 16px;
    margin: 0 0 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.secondary5};
  }
`

const BoxHeading = styled.div`
  margin-bottom: 16px;
  flex: 1 1 auto;
  h1 {
    line-height: 1.16667;
    font-family: 'DIN 1451 Std', sans-serif;
  }
  span,
  p {
    color: ${({ theme }) => theme.secondary6} !important;
  }
  @media (max-width: 768px) {
    h1 {
      text-align: center;
      font-size: 40px;
      margin: 10px 0;
    }
    .see-text {
      justify-content: center;
    }
  }
`

const WeAreSocialBox = styled.div`
  display: flex;

  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 44px;
    height: 44px;
    border: 2px solid transparent;
    margin-right: 5px;
    text-decoration: none;

    &:hover {
      border-color: ${({ theme }) => theme.secondary3};
    }

    &:last-child {
      color: ${({ theme }) => theme.primary10};
    }
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`

const SocialLinkBox = styled.div`
  display: flex;
  position: relative;
  padding-right: 11px;
  //margin-right: 10px;

  // &:after {
  //   content: '';
  //   position: absolute;
  //   z-index: 1;
  //   top: 10px;
  //   right: 0;
  //   height: calc(100% - 20px);
  //   width: 1px;
  //   background-color: ${({ theme }) => theme.secondary3};
  // }
  a {
    text-decoration: none;
    &:hover img {
      filter: brightness(3.75);
    }
  }
  @media (max-width: 768px) {
    padding-right: 0;
  }
  a {
    text-decoration: none;
    &:hover img {
      filter: brightness(3.75);
    }
  }
`

const OtherLinkBox = styled.div`
  display: flex;
`

const FollowsBox = styled.div`
  padding-left: ${gutterWidth}px;
  padding-right: ${gutterWidth}px;
  display: flex;
  flex: 0 0 230px;
  max-width: 230px;
  align-items: center;
  justify-content: end;
  flex-direction: column;
  margin-bottom: ${gutterWidth}px;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const FollowsBoxContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 16px;
  border: 1px solid ${({ theme }) => theme.secondary3};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.primary5};
    border-color: ${({ theme }) => theme.primary5};
    > * {
      color: ${({ theme }) => theme.secondary1};
    }
  }
  > * {
    font-family: 'Din Pro Bold', sans-serif;
  }
`

const TextFollow = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary6};
`

const TrafficFollowUp = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: ${({ theme }) => theme.white};
`

const CtaFollow = styled(EditButton)<{ followed?: boolean }>`
  padding: 10px;
  margin-top: 24px;
  text-transform: uppercase;
  font-family: 'Din Pro Bold', sans-serif;
  background-color: ${({ theme }) => theme.primary6};
  color: ${({ theme }) => theme.secondary1};
  &:focus {
    background-color: ${({ theme }) => theme.primary6};
  }
  ${({ followed }) =>
    followed &&
    css`
      background-color: ${({ theme }) => theme.secondary3};
      color: ${({ theme }) => theme.primary10};
      font-family: 'Din Pro Bold', sans-serif;
      &:focus {
        background-color: ${({ theme }) => theme.secondary3};
      }
    `}
`

const ClaimButtonWrapper = styled.div`
  margin-top: 24px;
`

export default Header
