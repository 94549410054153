import { Web3Provider } from '@ethersproject/providers'
import { SmartAccount, Transaction } from '@particle-network/aa'
import { Polygon, PolygonMumbai } from '@particle-network/chains'
import { metaMask, ParticleConnect, Provider, walletconnect } from '@particle-network/connect'
import { ALL_SUPPORTED_CHAIN_IDS, ChainIds } from 'constants/chains'
import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

export const WalletContext = createContext<{
  connectKit?: ParticleConnect
  provider?: Web3Provider
  clientWallet?: string
  account?: string
  chainId?: number
  getSmartAccount?: () => SmartAccount | undefined
  handleSmartOPTransaction?: (tx: Transaction) => Promise<string | undefined>
  connectWallet?: (id: string, options?: any) => void
  disconnectWallet?: () => void
  personalSign?: (digest: string) => Promise<string | undefined>
  authToken?: string | null
  setAuthToken?: React.Dispatch<React.SetStateAction<string | null>>
}>({})

function getChain(appChainId: string) {
  switch (parseInt(appChainId)) {
    case ChainIds.POLYGON:
      return Polygon
    case ChainIds.POLYGON_MUMBAI:
      return PolygonMumbai
    case ChainIds.AMOY:
      return Polygon
    default:
      return Polygon
  }
}

const smartAccountConfig = {
  particleKey: {
    projectId: process.env.REACT_APP_PROJECT_ID!,
    clientKey: process.env.REACT_APP_CLIENT_KEY!,
    appId: process.env.REACT_APP_APP_ID!,
  },
  smartAccount: {
    BICONOMY: [
      {
        version: '1.0.0',
        chainIds: ALL_SUPPORTED_CHAIN_IDS,
      },
      {
        version: '2.0.0',
        chainIds: ALL_SUPPORTED_CHAIN_IDS,
      },
    ],
    CYBERCONNECT: [
      {
        version: '1.0.0',
        chainIds: ALL_SUPPORTED_CHAIN_IDS,
      },
    ],
    SIMPLE: [
      {
        version: '1.0.0',
        chainIds: ALL_SUPPORTED_CHAIN_IDS,
      },
    ],
  },
  paymasterKey: [
    {
      chainId: 80002,
      apiKey: process.env.REACT_APP_BICONOMY_KEY!,
    },
  ],
}

export const WalletProvider = ({ children }: PropsWithChildren) => {
  const [provider, setProvider] = useState<Web3Provider>()
  const [smartAccountProvider, setSmartAccountProvider] = useState<Provider>()
  const [clientWallet, setClientWallet] = useState<string>()
  const [account, setAccount] = useState<string>()
  const [chainId, setChainId] = useState<number>()
  const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('authToken'))

  const getSmartAccount = useCallback(() => {
    if (!smartAccountProvider) {
      // throw new Error('NOT_CONNECTED_TO_SMART_ACCOUNT')
      return undefined
    }

    const smartAccount = new SmartAccount(smartAccountProvider as any, {
      ...smartAccountConfig.particleKey,
      aaOptions: {
        accountContracts: smartAccountConfig.smartAccount,
        paymasterApiKeys: smartAccountConfig.paymasterKey,
      },
    })

    smartAccount.setSmartAccountContract({
      name: 'BICONOMY',
      version: '1.0.0',
    })

    return smartAccount
  }, [smartAccountProvider])

  const handleSmartOPTransaction = useCallback(
    async (transaction: Transaction) => {
      const smartAccount = getSmartAccount?.()
      if (!smartAccount) return

      const feeQuotes = await smartAccount.getFeeQuotes(transaction)
      const { userOp, userOpHash } = feeQuotes.verifyingPaymasterGasless || feeQuotes.verifyingPaymasterNative
      const txHash = await smartAccount.sendUserOperation({ userOp, userOpHash })
      return txHash
    },
    [getSmartAccount]
  )

  useEffect(() => {
    const currentToken = localStorage.getItem('authToken')
    if (authToken !== currentToken) {
      localStorage.setItem('authToken', authToken || '')
    }
  }, [authToken])

  const appChainId = process.env.REACT_APP_CHAIN_ID || '137'
  const chain = getChain(appChainId)

  const connectKit = useMemo(() => {
    return new ParticleConnect({
      projectId: process.env.REACT_APP_PROJECT_ID as string,
      clientKey: process.env.REACT_APP_CLIENT_KEY as string,
      appId: process.env.REACT_APP_APP_ID as string,
      chains: [chain],
      wallets: [
        metaMask({ projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID! }),
        walletconnect({ projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID! }),
      ],
    })
  }, [])

  const connectToCachedProvider = useCallback(() => {
    const id = connectKit?.cachedProviderId()
    if (id) {
      connectKit
        ?.connectToCachedProvider()
        .then((provider: Provider) => {
          setProvider?.(new Web3Provider(provider as any))
          setSmartAccountProvider(provider)
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setProvider?.(undefined)
      setSmartAccountProvider?.(undefined)
    }
  }, [connectKit])

  const connectWallet = useCallback(
    async (id: string, options?: any) => {
      try {
        const connectProvider = await connectKit?.connect(id, options)
        setProvider?.(new Web3Provider(connectProvider as any))
        setSmartAccountProvider(connectProvider)
      } catch (error) {
        console.error('connectWallet', error)
      }
    },
    [connectKit]
  )

  const disconnectWallet = useCallback(async () => {
    try {
      await connectKit?.disconnect({ hideLoading: true })
    } catch (error) {
      console.error(error)
    }
    setProvider?.(undefined)
    setSmartAccountProvider?.(undefined)
  }, [])

  const personalSign = useCallback(
    async (digest: string) => {
      if (account && provider) {
        try {
          const signer = provider.getSigner()
          const result = await signer.signMessage(digest)
          return result
        } catch (error) {
          console.log('personal_sign', error)
        }
      }
      return undefined
    },
    [provider, account]
  )

  const getAccount = useCallback(async () => {
    const signer = provider?.getSigner()
    const account = await signer?.getAddress()
    if (account) {
      setAccount?.(account)
    }
  }, [provider])

  const getChainId = useCallback(async () => {
    const signer = provider?.getSigner()
    const chainId = await signer?.getChainId()

    if (chainId) {
      setChainId?.(chainId)
    }
  }, [provider])

  // TODO: handle this in a seperate way
  const getClientWallet = useCallback(async () => {
    setClientWallet(localStorage.getItem('WALLET_NAME') || '')
  }, [provider])

  const removeAuthToken = useCallback(() => {
    localStorage.removeItem('authToken')
    setAuthToken('')
  }, [])

  useEffect(() => {
    if (provider) {
      getAccount()
      getChainId()
      getClientWallet()
    } else {
      setAccount?.(undefined)
    }
  }, [provider])

  useEffect(() => {
    connectToCachedProvider()
    const onDisconnect = () => {
      removeAuthToken()
      setProvider?.(undefined)
    }

    const onChainChanged = () => {
      removeAuthToken()
      connectToCachedProvider()
    }

    const onAccountsChanged = () => {
      removeAuthToken()
      connectToCachedProvider()
    }

    connectKit?.on('disconnect', onDisconnect)
    connectKit?.on('chainChanged', onChainChanged)
    connectKit?.on('accountsChanged', onAccountsChanged)

    return () => {
      connectKit?.removeAllListeners()
    }
  }, [connectKit])

  return (
    <WalletContext.Provider
      value={{
        connectKit,
        provider,
        clientWallet,
        account,
        chainId,
        connectWallet,
        disconnectWallet,
        personalSign,
        authToken,
        setAuthToken,
        getSmartAccount,
        handleSmartOPTransaction,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}
