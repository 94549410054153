import React from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components/macro'

import IconMinus from '../../../../assets/images/svg/icon-minus.svg'
import IconPlus from '../../../../assets/images/svg/icon-plus.svg'
import { ButtonPrimary } from '../../../../components/Button'
import IconPortal from '../../../../components/IconPortal'
import ModalConfirmMint from '../../../../components/Modal/ModalConfirmMint'
import { SalePeriodType } from '../../../../entities/Sale'
import { MintingSaleInformation } from '../../../../entities/SaleInformation'
import { useModalConfirmMint, useModalOpen } from '../../../../state/application/hooks'
import { ApplicationModal } from '../../../../state/application/reducer'

interface MintProps {
  handleMint: () => void
  handleMintWithCreditCardPayment: () => void
  updateMintingSaleInformation: () => void
  quantity: number
  setQuantity: (quantity: number) => void
  activePeriod: SalePeriodType
  payWithMatic: boolean
  setPayWithMatic: (payWithMatic: boolean) => void
  userSaleData: MintingSaleInformation
}

const Mint = ({
  updateMintingSaleInformation,
  handleMint,
  handleMintWithCreditCardPayment,
  quantity,
  setQuantity,
  activePeriod,
  setPayWithMatic,
  payWithMatic,
  userSaleData,
}: MintProps) => {
  const toggleModalConfirmMint = useModalConfirmMint()
  const modalConfirmMintOpen = useModalOpen(ApplicationModal.CONFIRM_MINT)
  const handleClickDecrease = () => {
    if (quantity <= 1) {
      setQuantity(1)
    } else {
      setQuantity(quantity - 1)
    }
  }
  const handleClickIncrease = () => {
    if (activePeriod.maxPerMint > quantity && userSaleData.userMintedInPeriod + quantity < activePeriod.maxPerPeriod)
      setQuantity(quantity + 1)
  }
  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value)
    if (value) {
      setQuantity(value)
    } else {
      setQuantity(1)
    }
  }
  const _handleMint = () => {
    toggleModalConfirmMint()
  }
  return (
    <>
      <MintArea>
        <Flex>
          <Quantitywrapper>
            <Decrease onClick={handleClickDecrease} disabled={quantity === 1}>
              <IconPortal SrcImageIcon={IconMinus} widthIcon={'24px'} heightIcon={'24px'} />
            </Decrease>
            <Quantity>
              <FormInput type="number" value={quantity} onChange={handleChangeQuantity} min={1} />
            </Quantity>
            <Increase onClick={handleClickIncrease}>
              <IconPortal SrcImageIcon={IconPlus} widthIcon={'24px'} heightIcon={'24px'} />
            </Increase>
          </Quantitywrapper>
          <CtaMint onClick={_handleMint}>Mint</CtaMint>
        </Flex>
      </MintArea>
      <ModalConfirmMint
        mintingSaleInformation={userSaleData}
        quantity={quantity}
        handleMint={handleMint}
        handleMintWithCreditCardPayment={handleMintWithCreditCardPayment}
        updateMintingSaleInformation={updateMintingSaleInformation}
        isOpen={modalConfirmMintOpen}
        onDismiss={toggleModalConfirmMint}
        activePeriod={activePeriod}
        payWithMatic={payWithMatic}
        setPayWithMatic={setPayWithMatic}
      />
    </>
  )
}
const MintArea = styled.div`
  display: flex;
  justify-content: center;
`
const Quantitywrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.secondary2};
  height: 42px;
  display: flex;
  width: 148px;
`
const ButtonQuantity = styled.button`
  outline: none;
  background-color: transparent;
  border: none;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &[disabled] {
    outline: none;
    border: none;
    cursor: not-allowed;
  }
`
const Decrease = styled(ButtonQuantity)``
const Increase = styled(ButtonQuantity)``
const Quantity = styled.div`
  border-width: 0 2px 0 2px;
  border-color: ${({ theme }) => theme.secondary2};
  border-style: solid;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CtaMint = styled(ButtonPrimary)`
  font-family: 'Din Pro Bold', sans-serif;
  padding: 10px 24px;
  max-width: max-content;
  margin-left: 12px;
`
const FormInput = styled.input`
  background-color: transparent;
  outline: none;
  width: 100%;
  border: none;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  color: ${({ theme }) => theme.primary10};
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`
export default Mint
