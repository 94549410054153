import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'

type Address = `0x${string}`

interface IOrderStatus {
  id: number
  status: 'PAYMENT' | 'ONCHAIN'
  subStatus: 'NEW' | 'DONE'
  transactionType: 'MINT'
  saleAddress: Address
  assetId: number
  cryptoPrice: string
  fiatAmount: string | null
  cryptoAmountToPay: string
  cryptoCurrency: string
  fiatCurrency: string | null
  cryptoQuantity: number
  paymentTransactionId: number
  onchainTransactionId: number | null
  createdBy: Address
  createdAt: string
  updatedAt: string
  creator: {
    address: Address
    name: string
  }
}

interface IResponseOrderStatus {
  data: IOrderStatus
}

interface IParamsCheckOrderStatus {
  orderId: number
}

export const useOrderStatus = (
  options?: UseMutationOptions<IResponseOrderStatus, AxiosError, IParamsCheckOrderStatus>
) => {
  return useMutation<IResponseOrderStatus, AxiosError, IParamsCheckOrderStatus>(async ({ orderId }) => {
    const result = await axios.get(apiEndpoints.mintWithCreditCardOrderStatus(orderId), {
      headers: {
        authorization: localStorage.getItem('authToken') as string,
      },
    })

    return result?.data
  }, options)
}
