import axios from 'axios'
import { useEffect, useState } from 'react'
export const useExportCSV = (totalItems: any, URL: string) => {
  const [downloadData, setDownloadData] = useState<any>([])
  useEffect(() => {
    let isUnmounted = false
    if (!totalItems) {
      return
    }
    ;(async () => {
      const params = {
        page: 1,
        limit: totalItems,
      }
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}${URL}`, {
        headers: {
          authorization: localStorage.getItem('authToken') as string,
        },
        params,
      })
      const { data } = response
      if (isUnmounted) {
        setDownloadData([])
        return
      }
      setDownloadData(data.data)
    })()
    return () => {
      isUnmounted = true
    }
  }, [totalItems, URL])
  return downloadData
}
