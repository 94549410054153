import IconLeft from 'assets/images/svg/icon-chevronleft.svg'
import IconRight from 'assets/images/svg/icon-chevronright.svg'
import CopyHelper from 'components/Copy'
import { PaginationBox } from 'components/Pagination'
import { formatUnits } from 'ethers/lib/utils'
import { useAnother1ERC20Decimals } from 'hooks/useAnother1ERC20Decimals'
import { useExportCSV } from 'hooks/useExportCSV'
import useParticle from 'hooks/useParticle'
import Pagination from 'rc-pagination'
import Table from 'rc-table'
import { ColumnsType } from 'rc-table/lib/interface'
import * as React from 'react'
import { CSVLink } from 'react-csv'
import { Minus } from 'react-feather'
import { shortenAddress } from 'utils'
import { formatDateFromString } from 'utils/formatDate'

import { IClaimEvents } from '../hooks/useClaimEvents'
import * as Styled from '../styled'

type Props = {
  isChangeAirdropLoading: boolean
  isFetching: boolean
  isCheckedAll: boolean
  dataTable?: {
    data: IClaimEvents[]
    totalItems: number
    currentPage: number
    totalPages: number
  }

  confirmAirdropIds: number[]

  onPageChange: (page: number) => void
  onSelectUser: (id: number) => void
  onCheckedAll: () => void
}

const initialData = { totalItems: 0, currentPage: 1, totalPages: 1, data: [] }
export const NFTClaimedTable = ({
  isChangeAirdropLoading,
  isFetching,
  dataTable,
  confirmAirdropIds,
  isCheckedAll,
  onCheckedAll,
  onPageChange,
  onSelectUser,
}: Props) => {
  const { account } = useParticle()
  const { currentPage, totalItems, data } = dataTable ?? initialData

  const { data: decimals, isLoading: isLoadingDecimals } = useAnother1ERC20Decimals()

  const downloadData = useExportCSV(
    totalItems,
    'partners/{address}/user-claimed-nft'.replace(/{address}/, account ?? '')
  )

  const columns = React.useMemo(() => {
    const allColumns: ColumnsType<IClaimEvents> = [
      {
        title: (
          <div className={'th_checbox'}>
            <Styled.Checkbox>
              <input disabled={isChangeAirdropLoading} checked={isCheckedAll} type="checkbox" id="enable" readOnly />
              <label htmlFor="enable" onClick={onCheckedAll} />
            </Styled.Checkbox>
          </div>
        ),
        dataIndex: 'isAirdropCompleted',
        key: 'checkbox',
        width: 60,
        align: 'left' as const,
        className: 'secondary10',
        render(value, record) {
          if (value === true) return null

          return (
            <Styled.Checkbox>
              <input
                disabled={isChangeAirdropLoading}
                checked={confirmAirdropIds.includes(record.id)}
                type="checkbox"
                id="enable"
                readOnly
              />
              <label htmlFor="enable" onClick={() => onSelectUser(record.id)} />
            </Styled.Checkbox>
          )
        },
      },
      {
        title: '#',
        dataIndex: 'index',
        key: 'index',
        width: 60,
        align: 'left' as const,
        className: 'secondary10',
        render(value, row: any, index: number) {
          return <span>{index + 1 + (currentPage - 1) * 8}</span>
        },
      },
      {
        title: 'USER ADDRESS',
        dataIndex: 'userAddress',
        key: 'userAddress',
        align: 'left' as const,
        render(value) {
          return (
            <CopyHelper toCopy={value}>
              <Styled.TextAddress>{shortenAddress(value)}</Styled.TextAddress>
            </CopyHelper>
          )
        },
      },
      {
        title: 'NFC ID',
        dataIndex: 'nfcId',
        key: 'nfcId',
        align: 'left' as const,
        render(value) {
          return <span>{value}</span>
        },
      },
      {
        title: 'Collection',
        dataIndex: 'collectionAddress',
        key: 'collectionAddress',
        align: 'left' as const,
        className: 'secondary10',
        render(value: string) {
          return (
            <div className="address" style={{ textTransform: 'lowercase' }}>
              {value === '-' ? value : shortenAddress(value)}
            </div>
          )
        },
      },
      {
        title: 'Event Name',
        dataIndex: 'partner',
        key: 'partner.name',
        align: 'left' as const,
        render(value) {
          return <span>{value.name}</span>
        },
      },
      {
        title: 'Reward',
        dataIndex: 'partner',
        key: 'partner.rewardPerFlexer',
        align: 'right' as const,
        render(value, record) {
          if (isLoadingDecimals) return <Minus />
          return <span>{formatUnits(record.partner.rewardPerFlexer, decimals).toString()}</span>
        },
      },
      {
        title: 'Airdropped At',
        key: 'airdropAt',
        width: 140,
        align: 'left' as const,
        className: 'secondary10',
        render(value, record) {
          return <span>{record.isAirdropCompleted ? formatDateFromString(record.airdropAt) : ''}</span>
        },
      },
    ]
    return allColumns
  }, [confirmAirdropIds, currentPage, decimals, isChangeAirdropLoading, isLoadingDecimals, onSelectUser])

  const getHeaderCsv = [
    { label: 'USER ADDRESS', key: 'userAddress' },
    { label: 'NFC ID', key: 'nfcId' },
    { label: 'COLLECTION', key: 'collectionAddress' },
    { label: 'EVENT NAME', key: 'partner.name' },
    { label: 'REWARD', key: 'partner.rewardPerFlexer' },
    { label: 'AIRDROPPED AT', key: 'airdropAt' },
  ]

  return (
    <Styled.Wrapper>
      <Styled.PageTitle>Airdrop list</Styled.PageTitle>

      <Table
        rowKey={function (record) {
          return record.id
        }}
        columns={columns}
        data={data}
        className={'rc-table--cs'}
        scroll={{ x: 'max-content' }}
      />

      {!isFetching && totalItems > 7 && (
        <PaginationBox>
          <Pagination
            onChange={(current) => {
              onPageChange(current)
            }}
            pageSize={8}
            current={currentPage}
            total={totalItems}
            prevIcon={() => (
              <Styled.SpanIcon>
                <img src={IconLeft} alt={'icon_left'} />
              </Styled.SpanIcon>
            )}
            nextIcon={() => (
              <Styled.SpanIcon>
                <img src={IconRight} alt={'icon_right'} />
              </Styled.SpanIcon>
            )}
          />
        </PaginationBox>
      )}

      <Styled.BtnExport className="download">
        <CSVLink headers={getHeaderCsv} filename={`AN-1 User Claimed NFT`} data={downloadData} target="_blank">
          Export to CSV
        </CSVLink>
      </Styled.BtnExport>
    </Styled.Wrapper>
  )
}
