import { ButtonPrimary } from 'components/Button'
import ModalConfirm from 'components/Modal/ModalConfirm'
import ModalError from 'components/Modal/ModalError'
import ModalSuccess from 'components/Modal/ModalSuccess'
import useParticle from 'hooks/useParticle'
import { useMemo, useState } from 'react'

import { NFTClaimedTable } from './components/NFTClaimedTable'
import { useChangeAirdropStatus } from './hooks/useChangeAirdropStatus'
import { useClaimEvents } from './hooks/useClaimEvents'
import * as Styled from './styled'

export const NFTClaimedScreen = () => {
  const { account } = useParticle()
  const [confirmAirdropIds, setConfirmAirdropIds] = useState<number[]>([])
  const [page, setPage] = useState<number>(1)
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false)

  const {
    data: claimedEvent,
    isFetching,
    refetch,
  } = useClaimEvents(
    {
      queryParams: {
        page: page,
        limit: 8,
        address: account,
      },
    },
    { enabled: !!account, refetchOnWindowFocus: false }
  )

  const {
    mutate,
    isLoading: isChangeAirdropLoading,
    reset: resetChangeAirdropStatus,
    isSuccess: isChangeAirdropSuccess,
    isError: isChangeAirdropError,
    error: changeAirdropError,
  } = useChangeAirdropStatus({
    onSuccess: () => {
      setIsConfirmOpen(false)
      setConfirmAirdropIds([])
      refetch()
    },
  })

  const handleSelect = (id: number) => {
    if (confirmAirdropIds.includes(id)) {
      setConfirmAirdropIds(confirmAirdropIds.filter((item) => item !== id))
    } else {
      setConfirmAirdropIds([...confirmAirdropIds, id])
    }
  }

  const handleConfirm = () => {
    if (!account) return

    mutate({ address: account, ids: confirmAirdropIds })
  }

  const isCheckedAll = useMemo(() => {
    if (!claimedEvent) return false
    return claimedEvent.data
      .filter((item) => !item.isAirdropCompleted)
      .every((item) => confirmAirdropIds.includes(item.id))
  }, [claimedEvent, confirmAirdropIds])

  const handleCheckedAll = () => {
    if (!claimedEvent) return
    const confirmAirdropIdsClone = [...confirmAirdropIds]

    if (isCheckedAll) {
      claimedEvent.data
        .filter((item) => !item.isAirdropCompleted)
        .forEach((item) => {
          confirmAirdropIdsClone.splice(confirmAirdropIdsClone.indexOf(item.id), 1)
        })
    } else {
      claimedEvent.data
        .filter((item) => !item.isAirdropCompleted)
        .forEach((item) => {
          confirmAirdropIdsClone.push(item.id)
        })
    }

    setConfirmAirdropIds(confirmAirdropIdsClone)
  }

  return (
    <Styled.Wrapper>
      <Styled.SpaceBetween>
        <h2>Airdrop Management</h2>

        <div>
          <ButtonPrimary onClick={() => setIsConfirmOpen(true)} disabled={!confirmAirdropIds.length}>
            Confirm Airdrop
          </ButtonPrimary>
        </div>
      </Styled.SpaceBetween>

      <NFTClaimedTable
        isCheckedAll={isCheckedAll}
        onCheckedAll={handleCheckedAll}
        isChangeAirdropLoading={isChangeAirdropLoading}
        confirmAirdropIds={confirmAirdropIds}
        onSelectUser={handleSelect}
        isFetching={isFetching}
        dataTable={claimedEvent as any}
        onPageChange={(currentPage) => setPage(currentPage)}
      />

      {isConfirmOpen && (
        <ModalConfirm
          isOpen={isConfirmOpen}
          onDismiss={() => setIsConfirmOpen(false)}
          submit={handleConfirm}
          subText={'Are you sure you want to change airdrop status?'}
        />
      )}
      <ModalError
        isOpen={isChangeAirdropError}
        onDismiss={() => resetChangeAirdropStatus()}
        message={changeAirdropError ?? 'Change airdrop failed.'}
      />
      <ModalSuccess
        isOpen={isChangeAirdropSuccess}
        onDismiss={() => resetChangeAirdropStatus()}
        message={'Change airdrop successfully.'}
      />
    </Styled.Wrapper>
  )
}
