import { Contract } from '@ethersproject/contracts'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import useParticle from 'hooks/useParticle'

import abi from '../../../abis/phygital-items.json'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import { ClaimNFTByNFCCodeResponse } from '../models/ClaimNFTByNFCCodeResponse'

export const useClaimNFT = (options?: UseMutationOptions<any, any, ClaimNFTByNFCCodeResponse>) => {
  // const { library } = useActiveWeb3React()
  const { handleSmartOPTransaction, account, getSmartAccount } = useParticle()

  return useMutation<any, any, ClaimNFTByNFCCodeResponse>(async ({ tokenInformation, signature }) => {
    const smartAccount = getSmartAccount?.()
    const hasAA = !!smartAccount

    if (!hasAA) {
      throw new Error('NOT_CONNECTED_TO_PAY_MASTER')
    }

    const contract = new Contract(tokenInformation.collectionAddress, abi)
    const encodeParams = [tokenInformation.tokenId, account, signature]
    const transactionClaimNft = await contract.populateTransaction.claimNft(...encodeParams)
    const transaction = {
      to: tokenInformation.collectionAddress,
      data: transactionClaimNft.data,
    }
    const txHash = await handleSmartOPTransaction?.(transaction)
    return txHash
  }, options)
}
