import CopyIcon from 'assets/images/svg/icon-copy.svg'
import IconToken from 'assets/images/svg/icon-token.svg'
import { OptionCardClickable, OptionCardRight } from 'components/CardOption'
import Loader from 'components/Loader'
import { useBalance } from 'hooks/useBalance'
import useParticle from 'hooks/useParticle'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { shortenAddress } from 'utils'
import { weiToEther } from 'utils/balance'

import useExchangeRates from '../../hooks/useExchangeRates'
import { ButtonSecondary } from '../Button'
import { iconMap } from './iconMap'

export default function AccountDetails() {
  const { account, disconnectWallet, clientWallet, setAuthToken } = useParticle()
  const { balance } = useBalance()
  const balanceUSD = useExchangeRates()
  const walletIcon = useMemo(() => iconMap[localStorage.getItem('WALLET_NAME') || 'MetaMask'], [])
  const [notification, setNotification] = useState('')

  const onCopyClick = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(account || '')
      setNotification('Address copied!')
      setTimeout(() => setNotification(''), 3000)
    } catch (error) {
      console.log(error)
    }
  }, [account])

  const onDisconnect = useCallback(() => {
    disconnectWallet?.()
    setAuthToken?.(null)
  }, [disconnectWallet, setAuthToken])

  return (
    <>
      <UpperSection>
        <HeaderRow>Wallet</HeaderRow>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow>
                <IconWrapper>
                  <img src={walletIcon} alt={'Icon'} />
                </IconWrapper>
                <AccountControl>
                  <WalletName>{clientWallet}</WalletName>
                  <p>{account && shortenAddress(account)}</p>
                </AccountControl>
                <CopyWrapper onClick={onCopyClick}>
                  <img src={CopyIcon} alt={'Icon'} />
                </CopyWrapper>
                <WalletAction onClick={onDisconnect}>Disconnect</WalletAction>
              </AccountGroupingRow>
              {notification && <TextNotification>{notification}</TextNotification>}
            </InfoCard>
            <Balances>
              <h5>Available balances</h5>
              <OptionCardClickable id={1}>
                <IconWrapper>
                  <img src={IconToken} alt={'Icon'} />
                </IconWrapper>
                <OptionCardRight>
                  {weiToEther(balance, 3) === 'Loading' ? (
                    <Loader size={'24px'} />
                  ) : (
                    <>
                      <h5>{weiToEther(balance, 3)} MATIC</h5>
                      <span>{balanceUSD && new Intl.NumberFormat().format(balanceUSD)} USD</span>
                    </>
                  )}
                </OptionCardRight>
              </OptionCardClickable>
            </Balances>
          </YourAccount>
        </AccountSection>
      </UpperSection>
    </>
  )
}

const TextNotification = styled.div`
  background: ${({ theme }) => theme.secondary3};
  position: fixed;
  padding: 10px;
  left: 0;
  right: 0;
  width: 200px;
  margin-inline: auto;
  border-radius: 10px;
  bottom: 100px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.6;
  color: ${({ theme }) => theme.success};
  text-align: center;
`

const HeaderRow = styled.div`
  font-size: 28px;
  font-weight: 700;
  font-family: 'Din Pro Regular', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 45px;
  color: ${({ theme }) => theme.white};
  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding: 1rem;
`};
`

const UpperSection = styled.div`
  position: relative;

  h5 {
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
    font-weight: 400;
  }

  h5:last-child {
    margin-bottom: 0;
  }

  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const InfoCard = styled.div`
  padding: 1rem 0;
  position: relative;
  display: grid;
  grid-row-gap: 12px;
`

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary1};

  div {
    align-items: center;
  }
  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.secondary6};
  }
`

const IconWrapper = styled.div<{ size?: number | null }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '25px')};
    width: ${({ size }) => (size ? size + 'px' : '25px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`

const CopyWrapper = styled(IconWrapper)`
  cursor: pointer;
`

const AccountSection = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0rem 1rem 1.5rem 1rem;`};
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    font-weight: 500;
  }
`

const AccountControl = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  a:hover {
    text-decoration: underline;
  }
  p {
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.secondary6};
  }
`

const WalletName = styled.div`
  width: initial;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Din Pro Regular', sans-serif;
  color: ${({ theme }) => theme.white};
`

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  font-weight: 500;
  font-family: 'Din Pro Regular', sans-serif;
  margin-left: 8px;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 28px;
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.secondary3};
  border-radius: 0;
  :hover {
    cursor: pointer;
    border: none;
  }
`

const Balances = styled.div`
  margin-top: 10px;
  h5 {
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.secondary6};
  }
`
