import { useMutation, UseMutationOptions } from '@tanstack/react-query'
// eslint-disable-next-line no-restricted-imports
import { Contract } from 'ethers'
import useParticle from 'hooks/useParticle'

import { DefaultAn1Sale } from '../../../abis'
import abi from '../../../abis/default-an1-sale.json'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'
import { getFractalProof } from '../../../utils/fractal'

interface UseCreateEventInRegistryProps {
  nftDetail: any
  payWithMatic: boolean
  latestPriceInMatic: string
}

export const useRequestRedemptionInRegistry = (
  options?: UseMutationOptions<string, any, UseCreateEventInRegistryProps>
) => {
  // const { library } = useActiveWeb3React()
  const { provider, personalSign } = useParticle()

  return useMutation<any, any, UseCreateEventInRegistryProps>(
    async ({ nftDetail, payWithMatic, latestPriceInMatic }) => {
      if (!provider) {
        throw new Error('NOT_CONNECTED_TO_METAMASK')
      }

      const proofData = await getFractalProof(personalSign)

      const contract = new Contract(nftDetail.collection?.sale?.address, abi, provider.getSigner()) as DefaultAn1Sale

      const tx = await contract.requestRedemption(
        nftDetail.tokenId,
        payWithMatic,
        {
          signature: proofData.proof,
          validUntil: proofData.validUntil,
          approvedAt: proofData.approvedAt,
          maxAge: 0,
          fractalId: proofData.fractalId,
        },
        { value: payWithMatic ? latestPriceInMatic : 0 }
      )

      const receipt = await tx.wait()

      return receipt
    },
    options
  )
}
