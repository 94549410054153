import { useMutation, UseMutationOptions } from '@tanstack/react-query'
// eslint-disable-next-line no-restricted-imports
import { Contract } from 'ethers'
import useParticle from 'hooks/useParticle'

import abi from '../../../abis/partner-registry-abi.json'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

interface UseCancelEventInRegistry {
  eventId: number
  isCancelled: boolean
}

export const useCancelEventInRegistry = (options?: UseMutationOptions<string, any, UseCancelEventInRegistry>) => {
  // const { library } = useActiveWeb3React()
  const { provider } = useParticle()

  return useMutation<any, any, UseCancelEventInRegistry>(async ({ eventId, isCancelled }) => {
    if (!provider) {
      throw new Error('NOT_CONNECTED_TO_METAMASK')
    }

    const contract = new Contract(process.env.REACT_APP_PARTNER_REGISTRY_ADDRESS as string, abi, provider.getSigner())

    const tx = await contract.setEventCancellation(eventId, isCancelled)

    const receipt = await tx.wait()

    const EVENT_MODIFIED_INDEX = 0
    const EVENT_ID_ARGS_INDEX = 1

    return receipt.events[EVENT_MODIFIED_INDEX].args[EVENT_ID_ARGS_INDEX].toString()
  }, options)
}
