import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { apiEndpoints } from 'constants/apiEndpoints'
import { QueryKeys } from 'constants/queryKeys'
import { useApiClientContext } from 'context/ApiClientContext'

export type UseEventsQueryParams = Partial<IQueryParams>

export interface IQueryParams {
  page: number
  limit: number
  address: string
}

interface UseEventsProps {
  queryParams?: UseEventsQueryParams
}

interface IClaimEventsResponse {
  data: IClaimEvents[]
  totalItems: number
  currentPage: number
  totalPages: number
}

export const useClaimEvents = (
  params?: UseEventsProps,
  options?: UseQueryOptions<UseEventsProps, AxiosError<any>, IClaimEventsResponse>
) => {
  const { client } = useApiClientContext()

  return useQuery<UseEventsProps, AxiosError<any>, IClaimEventsResponse>(
    [QueryKeys.ClaimEvents, params?.queryParams],
    async () => {
      const result = await client.get(
        apiEndpoints.claimedEvents.replace(/{address}/, params?.queryParams?.address ?? ''),
        {
          headers: {
            authorization: localStorage.getItem('authToken') as string,
          },
          params: params?.queryParams,
        }
      )

      return result?.data
    },
    options
  )
}

export interface IClaimEvents {
  id: number
  userAddress: string
  collectionAddress: string
  nfcId: string
  tokenId: number
  claimAt: string
  eventId: number
  airdropAt: string
  isAirdropCompleted: boolean
  createdAt: string
  updatedAt: string
  partner: Partner
}

export interface Partner {
  eventId: string
  userAddress: string
  name: string
  startTime: string
  endTime: string
  imageKey: string
  formattedAddress: string
  location: Location
  viewport: any
  rewardPerFlexer: string
  tokenRewardPool: string
  isPOAPEnabled: boolean
  isCancelled: boolean
  createdAt: string
  updatedAt: string
}

export interface Location {
  latitude: number
  longitude: number
}
