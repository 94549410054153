import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ErrorBoundary from 'components/ErrorBoundary'
// import { ModalSwitchAccount } from 'components/ModalSwitchAccount'
// import { useReSign } from 'components/ModalSwitchAccount/useReSign'
import useParticle from 'hooks/useParticle'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useFetchProfile } from 'state/profile/hooks'
import styled from 'styled-components/macro'

import LayoutMain from '../components/Layout/LayoutMain'
import LayoutOth from '../components/Layout/LayoutOth'
import { routes } from '../constants/routes'
import { Main as AdminPanel } from '../modules/AdminPanel/screens/Main/Main'
import { AddClerks as AddClerksScreen } from '../modules/Events/screens/AddClerks'
import { AddCollection as AddCollectionScreen } from '../modules/Events/screens/AddCollection'
import { AddWhitelist as AddWhitelistScreen } from '../modules/Events/screens/AddWhitelist'
import { Create as EventsCreateScreen } from '../modules/Events/screens/Create'
import { Edit as EventsEditScreen } from '../modules/Events/screens/Edit'
import { Main as PartnerPanelScreen } from '../modules/PartnerPanel/screens/Main'
import AssignNFC from './AssignNFC'
import BecomeCreator from './BecomeCreator'
import CollectionDetail from './CollectionDetail'
import ConvertType from './ConvertType'
import EditCollectionInfo from './EditCollectionInfo'
import EditProfile from './EditProfile'
import LandingPage from './LandingPage'
import MintingCollection from './MintingCollection'
import NFTDetail from './NFTDetail'
import NotFound from './NotFound'
import PrivacyPolicy from './PrivacyPolicy'
import Profile from './Profile'
import ScannedNFC from './ScannedNFC'
import TermsAndConditions from './TermsAndConditions'
import TwitterAuth from './TwitterAuth'

const SentryRoute = Sentry.withSentryRouting(Route)

const RouteWithLayout = ({ component: Component, layout: Layout, ...rest }: any) => (
  <SentryRoute
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...rest} {...props} />
      </Layout>
    )}
  />
)

const queryClient = new QueryClient()

export default function App() {
  const { account } = useParticle()
  const handleFetchProfile = useFetchProfile()

  // const { isOpen, reauthenticate, isReauthorizing } = useReSign()

  useEffect(() => {
    if (!account) return

    handleFetchProfile(account)
  }, [account, handleFetchProfile])

  return (
    <ErrorBoundary>
      <Helmet>
        <meta
          name="description"
          content="BUILDING A PERMISSIONLESS PLATFORM

          Enhancing the secondary trading of sneakers and fashion collectibles."
        />
        <meta property="og:title" content="Another-1" />
        <meta property="og:image" content="../src/assets/logo/logo.svg" />
        <meta
          name="keywords"
          content="sneakerhead, nft, blockchain, marketplace, sneakers, gaming, collectible, avatar, metaverse, sneakerheadz-society, art"
        />

        <meta name="title" content="ANOTHER-1. IMAGINE. FALL. FOR PHYGITAL CREATIVITY" />
        <meta
          name="description"
          content="Inspired by the erosion of street art, another-1 is a multidisciplinary, creative lab on a mission to breathe new life into the graffiti art scene, both on the street; and on the blockchain by leveraging the power of Web3 to immortalize ‘THE WALLS’ in the form of an NFT collection."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://another-1.io" />
        <meta property="og:title" content="ANOTHER-1. IMAGINE. FALL. FOR PHYGITAL CREATIVITY" />
        <meta
          property="og:description"
          content="Inspired by the erosion of street art, another-1 is a multidisciplinary, creative lab on a mission to breathe new life into the graffiti art scene, both on the street; and on the blockchain by leveraging the power of Web3 to immortalize ‘THE WALLS’ in the form of an NFT collection."
        />
        <meta property="og:image" content="../src/assets/logo/logo.svg" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://another-1.io" />
        <meta property="twitter:title" content="ANOTHER-1. IMAGINE. FALL. FOR PHYGITAL CREATIVITY" />
        <meta
          property="twitter:description"
          content="Inspired by the erosion of street art, another-1 is a multidisciplinary, creative lab on a mission to breathe new life into the graffiti art scene, both on the street; and on the blockchain by leveraging the power of Web3 to immortalize ‘THE WALLS’ in the form of an NFT collection."
        />
        <meta property="twitter:image" content="../src/assets/logo/logo.svg" />
      </Helmet>
      <Route />
      <QueryClientProvider client={queryClient}>
        <AppWrapper>
          <Switch>
            <RouteWithLayout exact path="/" layout={LayoutMain} component={LandingPage} />
            <RouteWithLayout exact path="/project-nayom1" layout={LayoutMain}>
              <Redirect to="/collection/project-nayom1" />
            </RouteWithLayout>
            <RouteWithLayout layout={LayoutMain} path={'/edit-profile'} exact component={EditProfile} />
            <RouteWithLayout layout={LayoutMain} path={'/profile'} exact component={Profile} />
            <RouteWithLayout layout={LayoutMain} path={'/terms-and-conditions'} exact component={TermsAndConditions} />
            <RouteWithLayout layout={LayoutMain} path={'/privacy-policy'} exact component={PrivacyPolicy} />
            <RouteWithLayout layout={LayoutMain} path={'/user/:address'} exact component={Profile} />
            <RouteWithLayout layout={LayoutMain} path={'/collection/:id'} exact component={CollectionDetail} />
            <RouteWithLayout layout={LayoutMain} path={'/nft/:collectionAddress/:id'} exact component={NFTDetail} />
            <RouteWithLayout layout={LayoutMain} path={'/:id/mint'} exact component={MintingCollection} />
            <RouteWithLayout layout={LayoutMain} path={'/authenticate'} exact component={ScannedNFC} />
            <RouteWithLayout layout={LayoutMain} path={'/admin/assign-nfc'} exact component={AssignNFC} />
            <RouteWithLayout layout={LayoutMain} path={'/auth/twitter/callback'} exact component={TwitterAuth} />
            <RouteWithLayout layout={LayoutMain} path={'/become-creator'} exact component={BecomeCreator} />
            <RouteWithLayout
              layout={LayoutMain}
              path={routes.adminPanel.partnerEvents.edit}
              component={EventsEditScreen}
              returnUrl={routes.adminPanel.partnerEvents.index}
            />
            <RouteWithLayout layout={LayoutMain} path={routes.adminPanel.index} component={AdminPanel} />
            <RouteWithLayout layout={LayoutMain} path={routes.editEvents} component={EventsEditScreen} />
            <RouteWithLayout layout={LayoutMain} path={routes.partnerPanel.addClerks} component={AddClerksScreen} />
            <RouteWithLayout
              layout={LayoutMain}
              path={routes.partnerPanel.addCollection}
              component={AddCollectionScreen}
            />
            <RouteWithLayout
              layout={LayoutMain}
              path={routes.partnerPanel.addWhitelist}
              component={AddWhitelistScreen}
            />
            <RouteWithLayout layout={LayoutMain} path={routes.createEvents} component={EventsCreateScreen} />
            <RouteWithLayout layout={LayoutMain} path={routes.partnerPanel.index} component={PartnerPanelScreen} />
            <RouteWithLayout
              layout={LayoutMain}
              path={'/edit-collection/:addressCollection'}
              exact
              component={EditCollectionInfo}
            />
            <RouteWithLayout layout={LayoutOth} path={'/ntr1-claim'} exact component={ConvertType} />
            <RouteWithLayout layout={LayoutMain} path={'*'} exact component={NotFound} />
          </Switch>

          {/* <ModalSwitchAccount isOpen={isOpen} onReSign={reauthenticate} isReauthorizing={isReauthorizing} /> */}
        </AppWrapper>
      </QueryClientProvider>
    </ErrorBoundary>
  )
}

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 100vh;
`
