import React from 'react'
import { Link as LinkRouter } from 'react-router-dom'
import styled from 'styled-components/macro'
import { isVideoLink } from 'utils/helper'

import Fallback from '../../../../assets/images/fallback.svg'
import OverlayTop from '../../../../assets/images/img/mask_top.png'
import { ButtonPrimaryAnother } from '../../../../components/Button'
import CardNFT from '../../../../components/CardNFT'
import CollectionCountdown from '../../../MintingCollection/CollectionOtherItem/CollectionCountdown'
const CardSlide = (props: any) => {
  const { dataSlide } = props
  const { media } = dataSlide

  return (
    <Card>
      <BoxVideo>
        <MaskTop>
          <img src={OverlayTop} alt={'overlay'} />
        </MaskTop>
        {media.video && (
          <RatioVideo>
            {isVideoLink(media?.video) ? (
              <video loop muted autoPlay playsInline controls={false}>
                <source src={media?.video} type="video/mp4" />
              </video>
            ) : (
              <img src={media?.video} alt={'Banner'} onError={(e) => (e.currentTarget.src = Fallback)} />
            )}
          </RatioVideo>
        )}
      </BoxVideo>
      <CardBody>
        <CardContent>
          <CardLeft>
            <CardInfor>
              <Title>{dataSlide.name}</Title>
              {dataSlide?.sale && <CollectionCountdown sale={dataSlide.sale} />}
              <TimeBox>
                {dataSlide?.typeSale === 'publicSale' && dataSlide?.status === 'ongoing' && (
                  <HighLight>Minting on swappable</HighLight>
                )}
                {dataSlide?.typeSale === 'publicSale' && dataSlide?.status === 'upcoming' && (
                  <HighLight>COMING SOON</HighLight>
                )}
                {dataSlide?.typeSale === 'past' && <HighLight>PAST</HighLight>}
              </TimeBox>
              <CtaCollection type={'button'}>
                <LinkRouter to={`/collection/${dataSlide.slug}`}>EXPLORE COLLECTION</LinkRouter>
              </CtaCollection>
            </CardInfor>
          </CardLeft>
          <CardRight>
            <CardImageInfo>
              <LinkRouter to={`/collection/${dataSlide.slug}`}>
                {media?.nftSample && <CardNFT nftSample={media.nftSample} />}
              </LinkRouter>
            </CardImageInfo>
          </CardRight>
          <CtaCollection type={'button'} className={'cta-collection-xs'}>
            <LinkRouter to={`/collection/${dataSlide.slug}`}>EXPLORE COLLECTION</LinkRouter>
          </CtaCollection>
        </CardContent>
      </CardBody>
    </Card>
  )
}
const Card = styled.div`
  width: 100%;
  position: relative;
  height: calc(100vh - 160px);
  @media (max-width: 1024px) {
    height: calc(100vh - 190px);
  }
  @media (max-width: 768px) {
    height: auto;
  }
`
const CardBody = styled.div`
  padding: 150px 100px 60px;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  @media (max-width: 1366px) and (max-height: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media (max-width: 1024px) {
    padding: 40px 60px;
  }
  @media (max-width: 768px) {
    padding: 0 16px;
    transform: none;
    position: relative;
    left: auto;
    bottom: auto;
    margin-top: -150px;
  }
`
const CardContent = styled.div`
  display: flex;
  //align-items: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
const CardLeft = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 16px;
  @media (max-width: 1024px) {
    flex: 0 0 calc(100% - 242px);
    max-width: calc(100% - 242px);
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }
`
const CardInfor = styled.div`
  max-width: 560px;
  width: 100%;

  & div,
  h4 {
    text-align: left !important;
    justify-content: start;
  }
  @media (max-width: 768px) {
    text-align: center;
    max-width: 100%;
  }
`
const Title = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.display1};
  line-height: 1.1111;
  letter-spacing: -1px;
  margin-top: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: 'DIN 1451 Std', sans-serif;
  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.display2};
    margin-bottom: 12px;
  }
  @media (max-width: 575px) {
    font-size: ${({ theme }) => theme.display3};
  }
`
const CardRight = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 16px;
  display: flex;
  @media (max-width: 1024px) {
    flex: 0 0 242px;
    max-width: 242px;
  }
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
  }
`
const CardImageInfo = styled.div`
  max-width: 380px;
  width: 100%;
  margin-left: auto;
  a {
    text-decoration: none;
  }
  @media (max-width: 1024px) {
    max-width: none;
  }
  @media (max-width: 768px) {
    max-width: 220px;
    margin-right: auto;
  }
`
const BoxVideo = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  @media (max-width: 768px) {
    height: 400px;
  }
`
const RatioVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  right: 0;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    width: 100%;
  }
`
const CtaCollection = styled(ButtonPrimaryAnother)`
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  border: none;
  outline: none;
  &.cta-collection-xs {
    display: none;
  }
  &:hover {
    border: none;
    outline: none;
  }
  a {
    display: block;
    width: 100%;
    text-decoration: none;
    color: ${({ theme }) => theme.secondary1};
  }
  @media (max-width: 768px) {
    margin: 0 auto;
    display: none;
    &.cta-collection-xs {
      margin-top: 36px;
      display: flex;
    }
  }
`
const MaskTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`
const HighLight = styled.span`
  color: ${({ theme }) => theme.primary5};
  font-size: 20px;
  text-transform: uppercase;
`
const TimeBox = styled.div`
  flex: 0 0 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media (max-width: 575px) {
    margin-bottom: 12px;
  }
`

export default CardSlide
