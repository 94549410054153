export enum ChainIds {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,

  POLYGON = 137,
  POLYGON_MUMBAI = 80001,
  AMOY = 80002,
}

export function getChainId(id: string | undefined): ChainIds {
  if (!id) return ChainIds.MAINNET
  switch (parseInt(id)) {
    case 1:
      return ChainIds.MAINNET
    case 3:
      return ChainIds.ROPSTEN
    case 4:
      return ChainIds.RINKEBY
    case 5:
      return ChainIds.GOERLI
    case 42:
      return ChainIds.KOVAN
    case 137:
      return ChainIds.POLYGON
    case 80001:
      return ChainIds.POLYGON_MUMBAI
    case 80002:
      return ChainIds.AMOY
    default:
      return ChainIds.MAINNET
  }
}

export const ALL_SUPPORTED_CHAIN_IDS: ChainIds[] = [getChainId(process.env.REACT_APP_CHAIN_ID)]
