import { Contract } from '@ethersproject/contracts'
import abi from 'abis/phygital-items.json'
import { PhygitalItemStates } from 'constants/phygitalItemStates'
// import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useApi } from 'hooks/useApi'
import useParticle from 'hooks/useParticle'
import React, { useState } from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'
import styled from 'styled-components/macro'
import { getFractalProof } from 'utils/fractal'

import ErrorIcon from '../../../assets/icons/error.svg'
import IconInfo from '../../../assets/icons/icon-info-circle.svg'
import SuccessIcon from '../../../assets/icons/success.svg'
import IconClose from '../../../assets/images/svg/icon-close.svg'
import { InputVerificationCodeAnother } from '../../../theme'
import IconPortal from '../../IconPortal'
import Loader from '../../Loader'
import Modal from '../index'

export interface AssetDetails {
  collectionAddress: string
  tokenId: string
  nfcId?: string
}

interface ModalCodeAuthenticationProps {
  isOpen: boolean
  onDismiss: () => void
  reFetch: () => void
  name: string
  assetDetails: AssetDetails
}

const Assign_error_400 = 'This NFC id has already been assigned to another asset'
const Assign_error_404 = 'The code you are using is invalid or has expired'

const ModalCodeAuthentication = ({ isOpen, onDismiss, name, assetDetails, reFetch }: ModalCodeAuthenticationProps) => {
  const { post } = useApi()
  // const { account, library } = useActiveWeb3React()
  const { account, provider, personalSign } = useParticle()
  const [code, setCode] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)

  const close = () => {
    onDismiss()
    setCode('')
    setStatus('')
  }

  const onError = (e: any) => {
    setStatus('error')
    e.response.status === 400 ? setErrorMessage(Assign_error_400) : setErrorMessage(Assign_error_404)
    setLoading(false)
  }

  const handleAuthenticationVerificationCode = async (code: string) => {
    if (!account || !provider) return
    setCode(code)
    if (code.includes('_')) {
      return
    } else {
      setLoading(true)
      await post(
        {
          url: `${process.env.REACT_APP_SERVER_URL}redemption/getRedeemSignature/${code}`,
          data: assetDetails,
          callback: async (res: any) => {
            if (res.data.signature) {
              const contract = new Contract(assetDetails.collectionAddress, abi, provider.getSigner())
              try {
                const proofData = await getFractalProof(personalSign)
                const maxAge = 0

                const tx = await contract
                  .setState(Number(assetDetails.tokenId), PhygitalItemStates.REDEEMED, res.data.signature, {
                    signature: proofData.proof,
                    validUntil: proofData.validUntil,
                    approvedAt: proofData.approvedAt,
                    maxAge: maxAge,
                    fractalId: proofData.fractalId,
                  })
                  .catch((e: any) => {
                    console.log('=>', e)
                  })
                await tx.wait().then((res: any) => {
                  post(
                    {
                      url: `${process.env.REACT_APP_SERVER_URL}redemption/confirm/${assetDetails.collectionAddress}/${assetDetails.tokenId}`,
                      callback: async (res: any) => {
                        setCode('______')
                        reFetch()
                        setLoading(false)
                        setStatus('success')
                      },
                    },
                    true
                  )
                })
              } catch (e) {
                console.log('contract error', e)

                onError(e)
              }
            } else {
              console.log('signature error data', res.data)
              onError(res.data)
            }
          },
          errorCallback: (error: any) => {
            console.log('signature error', error)
            onError(error)
          },
        },
        true
      )
    }
  }

  const handleAssignVerificationCode = async (code: string) => {
    if (!account) return
    setCode(code)
    if (code.includes('_')) {
      return
    } else {
      setLoading(true)
      post(
        {
          url: `${process.env.REACT_APP_SERVER_URL}redemption/assignNFCId/${code}`,
          data: assetDetails,
          callback: async (res: any) => {
            if (res.status === 200) {
              setStatus('success')
              setLoading(false)
              setCode('______')
              reFetch()
            } else {
              onError(res.data)
            }
          },
          errorCallback: (error: any) => {
            onError(error)
          },
        },
        true
      )
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={close} name={name} maxWidth={980}>
      {name === 'modal_assign' ? (
        <ModalAuthWrapper>
          {isLoading ? (
            <Submitting>
              <Loader size={'54px'} />
              <h5>assigning nfc id</h5>
              <p>We are assigning the NFC id to the NFT, please wait a few seconds...</p>
            </Submitting>
          ) : (
            <>
              <ModalHeader>
                <MainHeading>
                  <Heading>Assign NFC</Heading>
                  <span onClick={close} style={{ cursor: 'pointer' }}>
                    <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
                  </span>
                </MainHeading>
                <SubHeading>Scan the NFC tag in the item and enter the verification code to assign it.</SubHeading>
              </ModalHeader>
              {status === 'error' ? (
                <Div className={status}>
                  <img src={ErrorIcon} alt="Icon" />
                  <h2 className="error">Invalid verification code </h2>
                  <p>
                    {errorMessage}.
                    <br />
                    Please check and enter the correct one.
                  </p>
                </Div>
              ) : status === 'success' ? (
                <Div>
                  <img src={SuccessIcon} alt="Icon" />
                  <h2 className="success">Congratulations</h2>
                  <p>You have successfully assigned an NFC to the NFT.</p>
                </Div>
              ) : (
                <ModalBody>
                  <TextVerification>Input the verification code</TextVerification>
                  <InputVerificationCodeBox>
                    <ReactInputVerificationCode
                      autoFocus={true}
                      value={code}
                      placeholder={'_'}
                      length={6}
                      onCompleted={handleAssignVerificationCode}
                    />
                  </InputVerificationCodeBox>
                  <Notification>
                    <IconPortal SrcImageIcon={IconInfo} widthIcon={'16px'} heightIcon={'16px'} />
                    <TextNoti>It may take a minute to receive your code</TextNoti>
                  </Notification>
                </ModalBody>
              )}
            </>
          )}
        </ModalAuthWrapper>
      ) : (
        <ModalAuthWrapper>
          {isLoading ? (
            <Submitting>
              <Loader size={'54px'} />
              <h5>authenticating</h5>
              <p>Authenticating your item, please wait a few seconds...</p>
            </Submitting>
          ) : (
            <>
              <ModalHeader>
                <MainHeading>
                  <Heading>NFC authentication </Heading>
                  <span onClick={close} style={{ cursor: 'pointer' }}>
                    <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
                  </span>
                </MainHeading>
                <SubHeading>Scan the NFC tag in the product to verify the authentication</SubHeading>
              </ModalHeader>
              {status === 'error' ? (
                <Div className={status}>
                  <img src={ErrorIcon} alt="Icon" />
                  <h2 className="error">Invalid verification code </h2>
                  <p>
                    The code you are using is invalid or has expired.
                    <br />
                    Please check and enter the correct one.
                  </p>
                </Div>
              ) : status === 'success' ? (
                <Div>
                  <img src={SuccessIcon} alt="Icon" />
                  <h2 className="success">Congratulations</h2>
                  <p>You have successfully authenticated your item.</p>
                </Div>
              ) : (
                <ModalBody>
                  <TextVerification>Input the verification code</TextVerification>
                  <InputVerificationCodeBox>
                    <ReactInputVerificationCode
                      autoFocus={true}
                      value={code}
                      placeholder={'_'}
                      length={6}
                      onCompleted={handleAuthenticationVerificationCode}
                    />
                  </InputVerificationCodeBox>
                  <Notification>
                    <IconPortal SrcImageIcon={IconInfo} widthIcon={'16px'} heightIcon={'16px'} />
                    <TextNoti>It may take a minute to receive your code</TextNoti>
                  </Notification>
                </ModalBody>
              )}
            </>
          )}
        </ModalAuthWrapper>
      )}
    </Modal>
  )
}
const ModalAuthWrapper = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.secondary1};
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  min-height: 280px;
`
const ModalHeader = styled.div`
  padding-bottom: 20px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -32px;
    width: calc(100% + 64px);
    height: 1px;
    background-color: ${({ theme }) => theme.secondary3};
  }
`
const MainHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Heading = styled.h3`
  text-transform: uppercase;
  margin: 0;
  line-height: 1.333334;
`
const SubHeading = styled.h5`
  margin-top: 6px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary6};
`
const ModalBody = styled.div``
const TextVerification = styled.h4`
  font-style: normal;
  font-weight: 500;
  line-height: 1.4;
  color: ${({ theme }) => theme.secondary7};
  text-align: center;
  margin: 20px 0;
`
const InputVerificationCodeBox = styled(InputVerificationCodeAnother)``

const Notification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
const TextNoti = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 143%;
  color: ${({ theme }) => theme.secondary6};
  margin: 0 0 0 8px;
`
const Submitting = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h5 {
    text-transform: uppercase;
    line-height: 1.5;
    margin: 20px 0 0 0;
    font-size: 32px;
    color: ${({ theme }) => theme.white};
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.5;
    margin: 0;
  }
`
const Div = styled.div`
  padding-top: 120px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .success {
    color: ${({ theme }) => theme.primary6};
  }
  .error {
    color: ${({ theme }) => theme.red5};
  }
  h2 {
    margin: 24px 0 0 0;
    text-align: center;
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    font-size: ${({ theme }) => theme.fontSizeText2};
    margin: 6px 0 0 0;
    text-align: center;
  }
`
export default ModalCodeAuthentication
