import axios from 'axios'
import { useEffect, useState } from 'react'
import { weiToEther } from 'utils/balance'

import { useBalance } from './useBalance'

export default function useExchangeRates() {
  const { balance } = useBalance()
  const [balanceUSD, setBalanceUSD] = useState<number>(0)

  useEffect(() => {
    if (balance) {
      axios.get<any, any>(`https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=ETH,USD,EUR`).then((data) => {
        const _USD = Number(weiToEther(balance, 3)) * data.USD
        setBalanceUSD(isNaN(_USD) ? 0 : _USD)
      })
    }
  }, [balance])

  return balanceUSD
}
