import { isMobile } from '@particle-network/connect'
import { AutoColumn } from 'components/Column'
import PopupBox from 'components/PopupBox'
import { CHAIN_INFO } from 'constants/chainInfo'
import { getChainId } from 'constants/chains'
import { SUPPORTED_WALLETS } from 'constants/wallet'
import { useApiClientContext } from 'context/ApiClientContext'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import useParticle from 'hooks/useParticle'
import usePrevious from 'hooks/usePrevious'
import { WarningModal } from 'modules/Assets/components/WarningModal/WarningModal'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePopupOpen, useWalletPopupToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import styled from 'styled-components/macro'

import AccountDetails from '../AccountDetails'
import { authMap } from './authMap'
import Option from './Option'
import { ParticleMethods } from './styled'

const WalletModal = () => {
  const walletPopupOpen = usePopupOpen(ApplicationModal.WALLET_POPUP)
  const toggleWalletPopup = useWalletPopupToggle()
  const { connectWallet, provider, account } = useParticle()
  const { reauthenticate } = useApiClientContext()
  const previousAccount = usePrevious(account)
  const windowVisible = useIsWindowVisible()
  const { chainId } = useParticle()
  const defaultChain = getChainId(process.env.REACT_APP_CHAIN_ID)
  const defaultChainInfo = CHAIN_INFO[defaultChain]
  const isValidChain = useMemo(() => !Boolean(chainId) || chainId === defaultChain, [chainId])
  const [isMetamakModalOpen, setMetamaskModal] = useState(false)
  useEffect(() => {
    if (!account) return
    if (
      isValidChain &&
      provider &&
      !localStorage.getItem('authToken') &&
      previousAccount !== account &&
      windowVisible
    ) {
      reauthenticate()
    }
  }, [account])

  const connect = useCallback(
    (walletName: string, id: string, options?: any) => {
      if (walletName === 'MetaMask' && isMobile() && !/MetaMaskMobile/i.test(navigator.userAgent)) {
        setMetamaskModal(true)
      } else {
        localStorage.setItem('WALLET_NAME', walletName)
        connectWallet?.(id, options)
      }
    },
    [connectWallet]
  )

  const onMetaMaskClick = useCallback(() => {
    window.location.replace(`https://metamask.app.link/dapp/${window.location.host}`)
  }, [])

  const disconnectedContent = useMemo(
    () => (
      <div>
        <div style={{ marginBottom: '15px' }}>
          <HeaderRow>Connect wallet</HeaderRow>
        </div>
        <ContentWrapper>
          <AutoColumn>
            <OptionGrid>
              {Object.keys(SUPPORTED_WALLETS).map((key) => {
                const option = SUPPORTED_WALLETS[key]
                return (
                  <Option
                    id={`connect-${key}`}
                    onClick={() => connect(option.name, option.connector)}
                    key={key}
                    color={option.color}
                    link={option.href}
                    header={option.name}
                    subheader={null}
                    icon={option.iconURL}
                  />
                  // )
                )
              })}
            </OptionGrid>
          </AutoColumn>
        </ContentWrapper>

        <ParticleMethods>
          {Object.keys(authMap).map((authType) => (
            <img
              key={authType}
              src={authMap[authType]}
              alt=""
              onClick={() =>
                connect(`Particle-${authType}`, 'particle', {
                  preferredAuthType: authType.toLowerCase(),
                })
              }
            />
          ))}
        </ParticleMethods>
        <div>
          <InfoBox style={{ textAlign: 'left' }}>
            By connecting your wallet you agree to our <a href="/">Terms of Service</a> and{' '}
            <a href="/">Privacy Policy</a>
          </InfoBox>
        </div>
      </div>
    ),
    [connectWallet]
  )

  const invalidChain = useMemo(
    () => (
      <div>
        <HeaderRow>Wrong Network</HeaderRow>
        <ContentWrapper>
          <h5>Connect to {defaultChainInfo.label} network by choosing it in the dropdown menu of your wallet.</h5>
        </ContentWrapper>
      </div>
    ),
    []
  )

  const content = useMemo(() => {
    let content = null
    if (provider && !isValidChain) {
      content = invalidChain
    } else if (provider && isValidChain) {
      content = <AccountDetails />
    } else {
      content = disconnectedContent
    }
    return content
  }, [provider, isValidChain, invalidChain, disconnectedContent])

  if (walletPopupOpen)
    return (
      <>
        <PopupBox isOpen={walletPopupOpen} onDismiss={toggleWalletPopup} className={'popup-wallet'}>
          {content}
        </PopupBox>
        {isMetamakModalOpen && (
          <WarningModal
            message="You'll be redirected to MetaMask in-browser"
            onOkayPress={onMetaMaskClick}
            onClose={() => setMetamaskModal(false)}
          />
        )}
      </>
    )

  return null
}

export default WalletModal

const HeaderRow = styled.div`
  font-size: 28px;
  font-weight: 700;
  font-family: 'Din Pro Regular', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 45px;
  color: ${({ theme }) => theme.white};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const ContentWrapper = styled.div`
  line-height: 1.6;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0 1rem 1rem 1rem`};
  & h5 {
    color: ${({ theme }) => theme.secondary6};
  }
`

const InfoBox = styled.div`
      padding-top: 20px;
      text-align: center;
      padding-bottom: 20px;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      color: ${({ theme }) => theme.secondary6};
      a {
        color: ${({ theme }) => theme.primary6};
      cursor: pointer;
  }

      span {
        font - weight: bold;
      color: ${({ theme }) => theme.white};
  }
      `

const OptionGrid = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  row-gap: 10px;
`
