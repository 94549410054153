import { useMutation, UseMutationOptions } from '@tanstack/react-query'
// eslint-disable-next-line no-restricted-imports
import { Contract } from 'ethers'
import useParticle from 'hooks/useParticle'

import { PartnerRegistryAbi } from '../../../abis'
import abi from '../../../abis/partner-registry-abi.json'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

interface UseSetWhitelistRootInRegistryProps {
  root: string
  eventId: string
}

export const useSetWhitelistRootInRegistry = (
  options?: UseMutationOptions<any, any, UseSetWhitelistRootInRegistryProps>
) => {
  // const { library } = useActiveWeb3React()
  const { provider } = useParticle()

  return useMutation<any, any, UseSetWhitelistRootInRegistryProps>(async ({ eventId, root }) => {
    if (!provider) {
      throw new Error('NOT_CONNECTED_TO_METAMASK')
    }

    const contract = new Contract(
      process.env.REACT_APP_PARTNER_REGISTRY_ADDRESS as string,
      abi,
      provider.getSigner()
    ) as PartnerRegistryAbi

    const result = await contract.setMerkleRoot(eventId, root)

    await result.wait()

    return result
  }, options)
}
