import { useMutation, UseMutationOptions } from '@tanstack/react-query'
// eslint-disable-next-line no-restricted-imports
import { Contract } from 'ethers'
import useParticle from 'hooks/useParticle'

import abi from '../../../abis/partner-registry-abi.json'
// import useActiveWeb3React from '../../../hooks/useActiveWeb3React'

interface UseAddClerksToEventInRegistryProps {
  eventId: number
  clerkAddresses: string[]
}

export const useAddClerksToEventInRegistry = (
  options?: UseMutationOptions<string, any, UseAddClerksToEventInRegistryProps>
) => {
  // const { library } = useActiveWeb3React()
  const { provider } = useParticle()

  return useMutation<any, any, UseAddClerksToEventInRegistryProps>(async ({ eventId, clerkAddresses }) => {
    if (!provider) {
      throw new Error('NOT_CONNECTED_TO_METAMASK')
    }

    const contract = new Contract(process.env.REACT_APP_PARTNER_REGISTRY_ADDRESS as string, abi, provider.getSigner())

    const tx = await contract.addClerksToEvent(eventId, clerkAddresses)

    const receipt = await tx.wait()

    return receipt
  }, options)
}
