// eslint-disable-next-line no-restricted-imports
import { Contract } from 'ethers'

import { Erc20 } from '../abis'
import abi from '../abis/erc20.json'
import useParticle from './useParticle'
// import useActiveWeb3React from './useActiveWeb3React'

export const useAnother1ERC20Contract = (): Erc20 | null => {
  // const { library, account } = useActiveWeb3React()
  const { provider, account } = useParticle()

  if (!provider || !account) {
    return null
  }

  const contract = new Contract(
    process.env.REACT_APP_ANOTHER1_ERC20_ADDRESS as string,
    abi,
    provider.getSigner()
  ) as Erc20

  return contract
}
