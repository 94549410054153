export enum PhygitalItemStates {
  REDEEMABLE,
  MANUFACTURING,
  REDEEMED,
  IN_CUSTODY,
  DECOUPLED,
}

export const PHYGITAL_ITEM_STATUS = {
  REDEEMABLE: 0,
  MANUFACTURING: 1,
  REDEEMED: 2,
  IN_CUSTODY: 3,
  DECOUPLED: 4,
}
