import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'

import { apiEndpoints } from '../../../constants/apiEndpoints'

type Address = `0x${string}`

interface IResponseCheckout {
  data: {
    orderNo: string
    orderTransactionId: number
    url: string
  }
}

interface IParamsCheckout {
  collectionAddress: Address
  crypto: 'MATIC' | 'USDT'
  type: 'MINT' | 'REDEEM'
  fiat: string
  quantity: number
  payWayCode: string
  tokenId?: number
}

export const useGenerateMintCheckout = (
  options?: UseMutationOptions<IResponseCheckout, AxiosError, IParamsCheckout>
) => {
  return useMutation<IResponseCheckout, AxiosError, IParamsCheckout>(async (payload) => {
    const result = await axios.post(apiEndpoints.mintWithCreditCardGenerateUrl, payload, {
      headers: {
        authorization: localStorage.getItem('authToken') as string,
      },
    })

    return result?.data
  }, options)
}
