import { ButtonSecondary } from 'components/Button'
import styled from 'styled-components/macro'

export const Wrapper = styled.div``

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PageTitle = styled.h5`
  line-height: 1.5;
  color: ${({ theme }) => theme.secondary7};
  margin: 0 0 12px 0;
`

export const SpanIcon = styled.span`
  display: block;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    vertical-align: middle;
  }
`

export const Checkbox = styled.div`
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSizeText2};
    line-height: 1.5;
    color: ${({ theme }) => theme.primary10};
    span {
      margin-right: 4px;
    }
    &::before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border-radius: 4px;
      border: 2px solid ${({ theme }) => theme.secondary3};
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  input:checked {
    & + label::after {
      content: '';
      display: block;
      position: absolute;
      top: 6px;
      left: 6px;
      width: 6px;
      height: 9px;
      border: solid ${({ theme }) => theme.secondary1};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    & + label::before {
      background-color: ${({ theme }) => theme.primary6};
      border-color: ${({ theme }) => theme.primary6};
    }
  }
`

export const BtnExport = styled(ButtonSecondary)`
  padding: 0 !important;
  margin-top: 16px;
  a {
    padding: 16px 32px !important;
    width: 100%;
    display: block;
    color: ${({ theme }) => theme.primary10};
    text-decoration: none;
  }
`

export const TextAddress = styled.span`
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary10};
  margin: 0;
  /* font-size: ${({ theme }) => theme.fontSizeText2}; */
  + span {
    color: ${({ theme }) => theme.secondary5};
  }
`
