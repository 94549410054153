import { yupResolver } from '@hookform/resolvers/yup'
import ErrorIcon from 'assets/icons/error.svg'
import SuccessIcon from 'assets/icons/success.svg'
import Fallback from 'assets/images/fallback.svg'
import { ButtonPrimary, ButtonSecondary } from 'components/Button'
import { FormInput } from 'components/FormInput'
import Loader from 'components/Loader'
import { ModalBase } from 'components/ModalBase/ModalBase'
// eslint-disable-next-line no-restricted-imports
import { ethers } from 'ethers'
import { metamaskErrorParser } from 'modules/Clerks/components/ClerkForm/utils/errorParser'
import React, { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components/macro'
import * as yup from 'yup'

import { INftInfo } from '../types'
import { useTransferNft } from './useTransferNft'

interface FormValues {
  address: string
}

type Props = {
  isOpen: boolean
  nftDetail?: INftInfo
  refetch: () => void
  onDismiss: () => void
}

const ModalTransferNFT = (props: Props) => {
  const { isOpen, onDismiss, nftDetail, refetch } = props

  return (
    <ModalBase maxWidth={650} isOpen={isOpen} onDismiss={onDismiss} header="Transfer NFT">
      <FormContent nftDetail={nftDetail} onDismiss={onDismiss} refetch={refetch} />
    </ModalBase>
  )
}

const schema = yup
  .object({
    address: yup
      .string()
      .required('Required')
      .test('validAddress', 'Invalid address', (value: string) => {
        return ethers.utils.isAddress(value)
      }),
  })
  .required()

const FormContent = ({ nftDetail, onDismiss, refetch }: Omit<Props, 'isOpen'>) => {
  const [isConfirm, setIsConfirm] = useState(false)

  const formValues = useForm({
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
    resetField,
    trigger,
    watch,
  } = formValues
  const addressValue = watch('address')

  const {
    mutateAsync: transferNft,
    isError: isErrorTransfer,
    isSuccess: isSuccessTransfer,
    error: errorTransfer,
    isLoading: loadingTransfer,
    reset: resetTransfer,
  } = useTransferNft({
    onSuccess() {
      resetField('address')
      refetch()
    },
    onError() {
      refetch()
    },
  })

  const errorMessage = useMemo(() => {
    if (errorTransfer) {
      return metamaskErrorParser(errorTransfer as any)
    }

    return ''
  }, [errorTransfer])

  const handleTransferNFT = async () => {
    const triggerResult = await trigger()
    if (triggerResult) {
      setIsConfirm(true)
    }
  }

  const handleConfirmTransfer = async (values: FormValues) => {
    if (!nftDetail) return

    transferNft({
      collectionAddress: nftDetail.collectionAddress,
      receiveAddress: values.address,
      tokenId: +nftDetail.tokenId,
    })
  }

  const handleClose = () => {
    onDismiss()
    resetField('address')
    setIsConfirm(false)
    resetTransfer()
  }

  if (isSuccessTransfer) {
    return (
      <ModalWrapper>
        <BoxResult>
          <img src={SuccessIcon} alt="Icon" />
          <h2 className="success">Successful!</h2>
          <p>Transfer NFT successful.</p>
        </BoxResult>
        <ButtonSecondary onClick={handleClose} type="button">
          Close
        </ButtonSecondary>
      </ModalWrapper>
    )
  }

  if (isErrorTransfer) {
    return (
      <ModalWrapper>
        <BoxResult>
          <img src={ErrorIcon} alt="Icon" />
          <h2 className="error">Error!</h2>
          <p>{errorMessage}</p>
        </BoxResult>
        <ButtonSecondary onClick={resetTransfer} type="button">
          Retry
        </ButtonSecondary>
      </ModalWrapper>
    )
  }

  return (
    <ModalWrapper>
      {!isConfirm ? (
        <ContainerHeading>
          <ContainerImage>
            <BoxImg>
              <img src={nftDetail?.image || Fallback} alt={'NFT'} />
            </BoxImg>
          </ContainerImage>
          <CollectionName>{nftDetail?.name}</CollectionName>
        </ContainerHeading>
      ) : null}

      <FormProvider {...formValues}>
        <FormWrapper onSubmit={handleSubmit(handleConfirmTransfer)}>
          {isConfirm ? (
            <Content>
              You are processing transfer NFT
              <br />
              <CollectionName style={{ display: 'inline' }}>{nftDetail?.name}</CollectionName>
              <br /> to <ReceiveAddress>{addressValue}</ReceiveAddress>
            </Content>
          ) : (
            <FormInput
              autoFocus
              customError={errorMessage}
              placeholder={'Please enter a wallet address'}
              name="address"
              label="Receive wallet address"
            />
          )}

          {isConfirm ? (
            <ActionConfirm>
              <ButtonSecondary
                disabled={isSubmitting || loadingTransfer}
                onClick={() => setIsConfirm(false)}
                type="button"
              >
                Back
              </ButtonSecondary>

              <ButtonPrimary type="submit" disabled={isSubmitting || loadingTransfer}>
                {isSubmitting || loadingTransfer ? <Loader stroke="#ffffff" size="20.5px" /> : 'Confirm'}
              </ButtonPrimary>
            </ActionConfirm>
          ) : (
            <ButtonPrimary type="button" onClick={handleTransferNFT}>
              Transfer
            </ButtonPrimary>
          )}
        </FormWrapper>
      </FormProvider>
    </ModalWrapper>
  )
}

export default ModalTransferNFT

const ContainerImage = styled.div`
  position: relative;
  height: 100px;
  aspect-ratio: 1/1;
`

const ContainerHeading = styled.div`
  display: flex;
  gap: 20px;
`

const BoxImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: ${({ theme }) => theme.secondary1};
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`

const ModalWrapper = styled.div`
  width: 100%;
  min-height: 250px;

  & > * {
    margin: 20px 0;
  }
`

const Content = styled.div`
  text-align: center;
  line-height: 30px;
`

const CollectionName = styled.h4`
  font-size: 16px;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;
  /* margin: 16px 0; */
  font-family: 'DIN 1451 Std', sans-serif;
`

const ReceiveAddress = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;
  /* margin: 16px 0; */
  font-family: 'DIN 1451 Std', sans-serif;
`

const FormWrapper = styled.form`
  & > * {
    margin: 20px 0;
  }
`

const ActionConfirm = styled.div`
  & > * {
    margin: 20px 0;
  }
`

const BoxResult = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
