export const routes = {
  NFTPreview: '/nft/{collectionAddress}/{tokenId}',
  // Admin Panel
  adminPanel: {
    index: '/admin',
    featuredCollections: '/admin/featured-collections',
    partnerEvents: {
      index: '/admin/events',
      edit: '/admin/:partnerAddress/events/:eventId/edit',
    },
    collectionManagement: {
      index: '/admin/collection-management',
      saleManagement: '/admin/collection-management/sale-management',
      editCollection: '/admin/collection-management/edit-collection',
      whitelists: '/admin/collection-management/whitelists',
    },
    phygital: {
      index: '/admin/phygital',
      redemption: '/admin/phygital/redemption',
      assignNfcId: '/admin/phygital/assign-nfc-id',
      settingAttributes: '/admin/phygital/setting-attributes',
      assignAttributes: '/admin/phygital/assign-attributes',
    },
    userManagement: {
      index: '/admin/user-management',
      blacklist: '/admin/user-management/blacklist',
    },
  },
  adminPanelEditEvents: '/admin/:partnerAddress/events/:eventId/edit',
  userProfile: '/profile',
  userProfileLikes: '/profile#tab?tab=likes',
  userProfileWatchlist: '/profile#tab?tab=watchlist',
  partnerPanel: {
    index: '/partner-panel',
    dashboard: '/partner-panel/dashboard',
    eventManagement: '/partner-panel/events',
    addClerks: '/partner-panel/:partnerAddress/events/:eventId/clerks',
    addCollection: '/partner-panel/:partnerAddress/events/:eventId/collection',
    addWhitelist: '/partner-panel/:partnerAddress/events/:eventId/whitelist',
    nftClaimedManagement: '/partner-panel/airdrop',
  },
  eventManagement: '/partner-panel/events',
  createEvents: '/partner-panel/events/create',
  editEvents: '/partner-panel/:partnerAddress/events/:eventId/edit',
}
