import { BigNumber } from '@ethersproject/bignumber'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import { formatUnits } from '@ethersproject/units'
import Fallback from 'assets/images/fallback.svg'
import useParticle from 'hooks/useParticle'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link as LinkRouter } from 'react-router-dom'
import { Flex } from 'rebass'
import { useGetCollectionDetailQuery } from 'state/collections/slice'
import styled from 'styled-components/macro'
import { shortenAddress } from 'utils'
import { isVideoLink } from 'utils/helper'

import abi from '../../abis/default-an1-sale.json'
import imgCreator from '../../assets/images/img/imgCreator.png'
import IconArrowRight from '../../assets/images/svg/icon-arrow-right.svg'
import BoxUserCard from '../../components/BoxUserCard/BoxUserCard'
import { ButtonOutline } from '../../components/Button'
import IconPortal from '../../components/IconPortal'
import Loader from '../../components/Loader'
import TruncateText from '../../components/TruncateText'
import { getActivePeriod, SalePeriodType } from '../../entities/Sale'
import { MintingSaleInformation } from '../../entities/SaleInformation'
// import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { SectionContentWrapper } from '../../theme'
import BoxMint from './BoxMint'
import CollectionOtherList from './CollectionOtherList'

export type CollectionParams = {
  id: string
}

const MintingCollection = () => {
  const { id } = useParams<CollectionParams>()
  // const { account, library } = useActiveWeb3React()
  const { account, provider } = useParticle()

  const [userSaleInformation, setUserSaleInformation] = useState<MintingSaleInformation>({
    ethBalance: BigNumber.from(0),
    erc20Balance: BigNumber.from(0),
    erc20Allowance: BigNumber.from(0),
    userMintedInPeriod: -1,
    totalMintedInPeriod: -1,
    totalMintedInCollection: -1,
    maxMintableInCollection: 0,
    latestPriceInMatic: BigNumber.from(0),
    latestPriceInErc20: BigNumber.from(0),
  })

  const {
    data: collection,
    isLoading,
    isError,
    refetch,
  } = useGetCollectionDetailQuery({
    collectionAddress: id,
    address: account,
  })
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const activePeriod: SalePeriodType = collection && getActivePeriod(collection.sale)

  const updateMintingSaleInformation = () => {
    if (activePeriod) {
      const saleContract = new Contract(activePeriod.saleAddress, abi, provider?.getSigner())
      saleContract
        .getSaleInformation(account ?? AddressZero, activePeriod.index)
        .then((res: any) =>
          setUserSaleInformation({
            ethBalance: BigNumber.from(res[0]._hex),
            erc20Balance: BigNumber.from(res[1]._hex),
            erc20Allowance: BigNumber.from(res[2]._hex),
            userMintedInPeriod: BigNumber.from(res[3]._hex).toNumber(),
            totalMintedInPeriod: BigNumber.from(res[4]._hex).toNumber(),
            totalMintedInCollection: BigNumber.from(res[5]._hex).toNumber(),
            maxMintableInCollection: BigNumber.from(res[6]._hex).toNumber(),
            latestPriceInMatic: BigNumber.from(res[7]._hex),
            latestPriceInErc20: BigNumber.from(res[8]._hex),
          })
        )
        .catch((e: any) => console.log(e))
    }
  }

  useEffect(() => {
    updateMintingSaleInformation()
    console.log(activePeriod)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, activePeriod])

  return !isLoading ? (
    <MintingCollectionWrapper>
      <InfoCollection>
        <DivRow>
          <DivInfoLeft>
            <DivInfoImg>
              <DivSquare>
                <BoxImg>
                  {isVideoLink(collection?.media?.image) ? (
                    <CustomVideo
                      width={'100%'}
                      height={'100%'}
                      src={collection.media?.image}
                      controls={false}
                      muted={true}
                      playsInline={true}
                      autoPlay={true}
                      loop={true}
                    ></CustomVideo>
                  ) : (
                    <img
                      src={collection?.media?.image || Fallback}
                      alt={'img_shoe_nft'}
                      onError={(e) => (e.currentTarget.src = Fallback)}
                    />
                  )}
                </BoxImg>
              </DivSquare>
            </DivInfoImg>
          </DivInfoLeft>
          <DivInfoRight>
            <Flex>
              <LinkRouter to={`/collection/${collection?.slug}`} style={{ textDecoration: 'none' }}>
                <CtaBack>
                  <IconPortal SrcImageIcon={IconArrowRight} widthIcon={'20px'} heightIcon={'20px'} />
                  <span>Back to detail</span>
                </CtaBack>
              </LinkRouter>
              {/* <DivAction>
                <BoxShareMore>
                  <IconBox>
                    <img src={IconShareImg} alt={'share'} />
                  </IconBox>
                  <IconBox style={{ marginRight: 0 }}>
                    <img src={IconMoreImg} alt={'more'} />
                  </IconBox>
                </BoxShareMore>
              </DivAction> */}
            </Flex>
            <TitleWrapper>
              <HeadingName>{collection?.name}</HeadingName>
              <Flex justifyContent="space-between">
                <BoxCreator>
                  <LinkRouter to={`/user/${collection?.creator.address}`}>
                    <BoxUserCard
                      img={collection?.creator.avatar ? collection?.creator.avatar : imgCreator}
                      labelName={'Created by'}
                      address={collection?.creator?.address}
                      name={
                        collection?.creator
                          ? collection?.creator.name
                          : collection?.creator.address
                          ? shortenAddress(collection?.creator.address)
                          : ''
                      }
                    />
                  </LinkRouter>
                </BoxCreator>
              </Flex>
            </TitleWrapper>
            <BoxAvailable>
              <StatusMint>{activePeriod?.saleType === 0 ? 'PRESALE' : 'PUBLIC SALE'}</StatusMint>
              <AboutMint>
                {activePeriod?.saleType === 0 && <AboutMintItem>For whitelisted addresses only</AboutMintItem>}
                {account && (
                  <AboutMintItem>
                    You can mint up to <SpanWhite>{activePeriod?.maxPerMint}</SpanWhite> NFTs per tx.
                  </AboutMintItem>
                )}
                <AboutMintItem>
                  <SpanGreen>
                    {userSaleInformation?.totalMintedInPeriod}/{activePeriod?.maxAllocation}
                  </SpanGreen>{' '}
                  NFTs have been minted so far on this sale stage
                </AboutMintItem>
                <AboutMintItem>
                  Cost <SpanGreen>{formatUnits(userSaleInformation?.latestPriceInErc20, 6)} USDT</SpanGreen> per piece
                </AboutMintItem>
                {account && (
                  <AboutMintItem color={'purple'}>
                    The current minting progress is{' '}
                    <SpanWhite>{userSaleInformation?.totalMintedInCollection || 0}</SpanWhite> /{' '}
                    <SpanWhite>{userSaleInformation?.maxMintableInCollection}</SpanWhite> NFTs in the entire collection.
                  </AboutMintItem>
                )}
              </AboutMint>
            </BoxAvailable>
            {collection && (
              <BoxMint
                activePeriod={activePeriod}
                collection={collection}
                isLoading={isLoading}
                isError={isError}
                reFetch={refetch}
                userSaleData={userSaleInformation}
                updateMintingSaleInformation={updateMintingSaleInformation}
              />
            )}

            <Description>
              <h5>About the collection</h5>
              <TruncateText text={collection?.descriptions?.shortBios} />
            </Description>
          </DivInfoRight>
        </DivRow>
      </InfoCollection>

      <OtherOngoingCollection>
        <CollectionOtherList collectionAddress={collection?.address} />
      </OtherOngoingCollection>
    </MintingCollectionWrapper>
  ) : (
    <Loader size="24px" />
  )
}

const OtherOngoingCollection = styled.div`
  margin-top: 80px;
  padding-top: 80px;
  border-top: 1px solid ${({ theme }) => theme.secondary2};

  @media (max-width: 768px) {
    margin-top: 20px;
    padding-top: 20px;
  }
`

const SpanWhite = styled.span`
  color: ${({ theme }) => theme.primary10};
`
const SpanGreen = styled.span`
  color: ${({ theme }) => theme.primary5};
`
const AboutMint = styled.div``
const AboutMintItem = styled.div<{ color?: string }>`
  color: ${({ theme }) => theme.secondary6};
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  padding-left: 16px;
  position: relative;
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 6px;
    width: 4px;
    height: 12px;
    background-color: ${({ theme, color }) => (color === 'purple' ? theme.secondaryBlue5 : theme.primary5)};
  }
`
const StatusMint = styled.p`
  text-transform: uppercase;
  font-family: 'Din Pro Bold', sans-serif;
  color: ${({ theme }) => theme.secondary10};
  margin: 0 0 10px 0;
`
const MintingCollectionWrapper = styled(SectionContentWrapper)`
  margin-top: 60px;
  @media (max-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`
const InfoCollection = styled.div``
const DivRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    margin-left: -20px;
    margin-right: -20px;
  }
  @media (max-width: 768px) {
    margin-left: -16px;
    margin-right: -16px;
  }
`
const DivInfoLeft = styled.div`
  flex: 0 0 52%;
  max-width: 52%;
  padding-left: 40px;
  padding-right: 40px;
  @media (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    flex: 0 0 40%;
    max-width: 40%;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    flex: 0 0 300px;
    max-width: 300px;
    margin: 0 auto 32px;
  }
`
const DivInfoRight = styled.div`
  flex: 0 0 48%;
  max-width: 48%;
  padding-left: 40px;
  padding-right: 40px;
  @media (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    flex: 0 0 60%;
    max-width: 60%;
  }
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    flex: 0 0 100%;
    max-width: 100%;
  }
`
const DivInfoImg = styled.div`
  position: relative;
`
const DivSquare = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  cursor: pointer;
  &:hover {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(180deg, rgba(6, 24, 18, 0.9) 12.42%, rgba(6, 24, 18, 0) 100%);
      z-index: 2;
    }
    .action-hover {
      display: flex;
    }
  }
`
const BoxAvailable = styled.div`
  margin-top: 50px;
  margin-bottom: 40px;
  padding: 20px 20px 20px 24px;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  border: 1px solid ${({ theme }) => theme.secondary3};
  color: ${({ theme }) => theme.secondary6};

  @media (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`
const BoxImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-color: ${({ theme }) => theme.secondary1};
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`
// const DivAction = styled.div`
//   display: flex;
//   margin-left: auto;
// `
//
// const BoxShareMore = styled.div`
//   display: flex;
//   margin-left: auto;
// `
// const IconBox = styled.span`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 42px;
//   height: 42px;
//   border: 1px solid ${({ theme }) => theme.secondary3};
//   margin-right: 8px;
//   cursor: pointer;
//   img {
//     display: block;
//     width: 24px;
//     height: 24px;
//   }
//   transition: all 0.5s ease-in-out;
//   &:hover {
//     border: 1px solid ${({ theme }) => theme.secondary6};
//   }
// `
const TitleWrapper = styled.div`
  margin-top: 40px;
`
const HeadingName = styled.p`
  font-size: 60px;
  font-family: 'DIN 1451 Std', serif;
  line-height: 1.111111;
  color: ${({ theme }) => theme.white};
  text-transform: uppercase;
  margin: 0;
`
const BoxCreator = styled.div`
  a {
    text-decoration: none;
  }
`
const CtaBack = styled(ButtonOutline)`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  font-family: 'Din Pro Bold', sans-serif;
  max-width: max-content;
  text-transform: capitalize;
  border: 1px solid ${({ theme }) => theme.secondary3};
  & > span:last-of-type {
    margin-left: 10px;
    color: ${({ theme }) => theme.primary10};
    line-height: 1.5;
  }
  &:hover {
    border-color: ${({ theme }) => theme.secondary6};
    background-color: transparent;
  }
  &:active {
    border-color: ${({ theme }) => theme.primary10};
  }
`
const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.5;
  margin-bottom: 16px;
  h5 {
    margin: 0 0 16px 0;
    color: ${({ theme }) => theme.white};
    text-transform: uppercase;
  }
  p {
    color: ${({ theme }) => theme.secondary6};
    margin: 0;
  }
  div {
    span {
      color: ${({ theme }) => theme.secondary6};
    }
  }
`
const CustomVideo = styled.video`
  min-width: auto !important;
`
export default MintingCollection
