import { ButtonPrimary } from 'components/Button'
import React, { useCallback, useState } from 'react'

import IconClose from '../../../../assets/images/svg/icon-close.svg'
import IconPortal from '../../../../components/IconPortal'
import { CloseContainer, Heading, ModalAuthWrapper, ModalContent, ModalHeader, PopupWrapper } from './styles'

interface WarningProps {
  onOkayPress?: () => void
  onClose?: () => void
  message?: string
}

export const WarningModal = ({
  onClose,
  onOkayPress,
  message = 'Please proceed by signing the message on the wallet',
}: WarningProps) => {
  const [isOpen, setIsOpen] = useState(true)

  const onDismiss = useCallback(() => {
    setIsOpen(false)
    onClose?.()
  }, [onClose, setIsOpen])

  return (
    <PopupWrapper isOpen={isOpen} onDismiss={onDismiss}>
      <ModalAuthWrapper>
        <ModalHeader>
          <CloseContainer>
            <span onClick={onDismiss} style={{ cursor: 'pointer' }}>
              <IconPortal SrcImageIcon={IconClose} widthIcon={'20px'} heightIcon={'20px'} />
            </span>
          </CloseContainer>
          <ModalContent>
            <Heading>{message}</Heading>
            {onOkayPress && <ButtonPrimary onClick={onOkayPress}>OK</ButtonPrimary>}
          </ModalContent>
        </ModalHeader>
      </ModalAuthWrapper>
    </PopupWrapper>
  )
}
