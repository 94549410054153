import PopupBox from 'components/PopupBox'
import styled from 'styled-components/macro'

export const ModalAuthWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 20%;
  padding: 32px;
  background-color: ${({ theme }) => theme.secondary1};
  width: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  min-height: 280px;
  display: flex;
  flex-direction: coloumn;
`

export const ModalHeader = styled.div`
  padding-bottom: 20px;
  position: relative;
`

export const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`

export const Heading = styled.h3`
  text-transform: uppercase;
  margin: 0;
  line-height: 1.333334;
  color: ${({ theme }) => theme.white};
`
export const ModalContent = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 20px;
`
export const PopupWrapper = styled(PopupBox)`
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
`
