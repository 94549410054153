import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { apiEndpoints } from 'constants/apiEndpoints'
import { useApiClientContext } from 'context/ApiClientContext'

export const useChangeAirdropStatus = (
  options?: UseMutationOptions<ChangeAirdropStatusResponse, any, ChangeAirdropStatusParams>
) => {
  const { client } = useApiClientContext()

  return useMutation<ChangeAirdropStatusResponse, any, ChangeAirdropStatusParams>(async ({ address, ids }) => {
    const result = await client.patch(
      apiEndpoints.changeAirdropStatus.replace(/{address}/, address ?? ''),
      {
        ids,
      },
      {
        headers: {
          authorization: localStorage.getItem('authToken') as string,
        },
      }
    )

    return result?.data
  }, options)
}

interface ChangeAirdropStatusResponse {
  message: string
}

interface ChangeAirdropStatusParams {
  address: string
  ids: number[]
}
