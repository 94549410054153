export const apiEndpoints = {
  redeemNFTByNFCCode: `${process.env.REACT_APP_SERVER_URL}redemption/by-nfc-code`,
  addUserRole: `${process.env.REACT_APP_SERVER_URL}admin-panel/role`,
  partnerEvents: `${process.env.REACT_APP_SERVER_URL}partners/{partnerAddress}/events`,
  eventsSummary: `${process.env.REACT_APP_SERVER_URL}partners/{partnerAddress}/events/summary`,
  event: `${process.env.REACT_APP_SERVER_URL}partners/{partnerAddress}/events/{eventId}`,
  events: `${process.env.REACT_APP_SERVER_URL}events`,
  uploadUrl: `${process.env.REACT_APP_SERVER_URL}upload`,
  eventClerks: `${process.env.REACT_APP_SERVER_URL}partners/{partnerAddress}/events/{eventId}/clerks`,
  eventClerk: `${process.env.REACT_APP_SERVER_URL}partners/{partnerAddress}/events/{eventId}/clerks/{clerkAddress}`,
  clerks: `${process.env.REACT_APP_SERVER_URL}partners/{partnerAddress}/events/clerks`,
  getAllowedCollections: `${process.env.REACT_APP_SERVER_URL}partners/{partnerAddress}/events/{eventId}/allowed-collections`,
  updateAllowedCollectionToEvent: `${process.env.REACT_APP_SERVER_URL}partners/{partnerAddress}/events/{eventId}/allowed-collections/{collectionAddress}`,
  poaps: `${process.env.REACT_APP_SERVER_URL}poaps/{address}`,
  authorize: `${process.env.REACT_APP_SERVER_URL}auth/{address}`,
  verifyAuthorization: `${process.env.REACT_APP_SERVER_URL}auth/{challenge}/{signature}`,
  eventWhitelist: `${process.env.REACT_APP_SERVER_URL}partners/{partnerAddress}/events/{eventId}/whitelist`,

  lockPartner: `${process.env.REACT_APP_SERVER_URL}admin-panel/role/lock`,

  fillShippingInformation: `${process.env.REACT_APP_SERVER_URL}redemption/fillShippingInformation`,
  shippingInformation: `${process.env.REACT_APP_SERVER_URL}redemption/collection/:collectionAddress/token/:tokenId/shippingInformation`,
  confirmRedemption: `${process.env.REACT_APP_SERVER_URL}redemption/confirm/:collectionAddress/:tokenId`,

  mintWithCreditCardGenerateUrl: `${process.env.REACT_APP_SERVER_URL}payment/generate-url`,
  mintWithCreditCardOrderStatus: (orderId: number) => `${process.env.REACT_APP_SERVER_URL}orders/${orderId}`,

  claimedEvents: `${process.env.REACT_APP_SERVER_URL}partners/{address}/user-claimed-nft`,
  changeAirdropStatus: `${process.env.REACT_APP_SERVER_URL}partners/{address}/user-claimed-nft/change-airdrop-status`,
}
