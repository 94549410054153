import { Contract } from '@ethersproject/contracts'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { PhygitalItems } from 'abis'
import abi from 'abis/phygital-items.json'
import useParticle from 'hooks/useParticle'

type Payload = {
  collectionAddress: string
  receiveAddress: string
  tokenId: number
}

export const useTransferNft = (options?: UseMutationOptions<any, Error, Payload>) => {
  const { account, provider } = useParticle()

  return useMutation<any, Error, Payload>(async ({ collectionAddress, receiveAddress, tokenId }) => {
    if (!provider || !account) {
      throw new Error('NOT_CONNECTED_TO_METAMASK')
    }

    const contract = new Contract(collectionAddress, abi, provider.getSigner()) as PhygitalItems
    const tx = await contract.transferFrom(account, receiveAddress, tokenId)

    await tx.wait(3)
    return tx
  }, options)
}
