export const QueryKeys = {
  Events: 'Events',
  Event: 'Event',
  PartnerEvents: 'PartnerEvents',
  EventsSummary: 'EventsSummary',
  EventClerks: 'EventClerks',
  GetAllowedCollections: 'GetAllowedCollections',
  Erc20Decimals: 'Erc20Decimals',
  Erc20Balance: 'Erc20Balance',
  AccountBalance: 'AccountBalance',
  RedemptionInformation: 'RedemptionInformation',
  ShippingInformation: 'ShippingInformation',
  ClaimEvents: 'ClaimEvents',
  useTransferNft: 'useTransferNft',
}
