import { AccountPopup } from 'components/AccountPopup'
import WalletModal from 'components/WalletModal'
import { getChainId } from 'constants/chains'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import useParticle from 'hooks/useParticle'
import { ClaimNFTButton } from 'modules/Assets/components/ClaimNFTButton'
import { useMemo, useRef, useState } from 'react'
import { ChevronDown } from 'react-feather'
import { Link as LinkRouter, NavLink } from 'react-router-dom'
import { useAccountPopup, useAccountPopupToggle, usePopupOpen, useWalletPopupToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useGetSearchResultsQuery } from 'state/search/slice'
import styled, { css } from 'styled-components/macro'
import { useDebounce } from 'use-debounce'

import IconSearch from '../../assets/icons/search.png'
import { ReactComponent as ProfileIcon } from '../../assets/images/Profile.svg'
import { ReactComponent as WalletIcon } from '../../assets/images/wallet.svg'
import logo from '../../assets/logo/logo.svg'
import { ButtonOutline, ButtonPrimary } from '../Button'
import IconPortal from '../IconPortal'
import CollectionsMenu from './CollectionsMenu'
import SearchBar from './SearchBar'
import SearchSuggestions from './SearchSuggestions'
type Custom = {
  visible?: boolean
}

const Navbar = () => {
  const [visible] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [showCollections, setShowCollections] = useState(false)
  const [showSearchMobileContainer, setSearchMobileContainer] = useState(false)
  const [debouncedSearch] = useDebounce(searchQuery, 800)
  const { account, provider, chainId } = useParticle()
  const {
    data: searchResults,
    isLoading,
    isFetching,
  } = useGetSearchResultsQuery(
    { searchQuery: debouncedSearch, account },
    {
      skip: !searchQuery || searchQuery.trim().length === 0,
    }
  )

  const toggleWalletPopup = useWalletPopupToggle()
  const toggleAccountPopup = useAccountPopupToggle()

  const walletNode = useRef<HTMLDivElement>()
  const accountNode = useRef<HTMLDivElement>()
  const menuNode = useRef<HTMLDivElement>()
  const searchNode = useRef<HTMLDivElement>()
  const searchMobileNode = useRef<HTMLDivElement>()
  const walletPopupOpen = usePopupOpen(ApplicationModal.WALLET_POPUP)
  const accountPopupOpen = useAccountPopup(ApplicationModal.ACCOUNT_POPUP)
  const defaultChain = getChainId(process.env.REACT_APP_CHAIN_ID)
  const isValidChain = useMemo(() => !Boolean(chainId) || chainId === defaultChain, [chainId])

  useOnClickOutside(accountNode, accountPopupOpen ? toggleAccountPopup : undefined)
  useOnClickOutside(walletNode, walletPopupOpen ? toggleWalletPopup : undefined)
  useOnClickOutside(menuNode, showCollections ? () => setShowCollections(false) : undefined)
  useOnClickOutside(searchMobileNode, () => setSearchMobileContainer(false))

  const [isOpenNavMenuMobile, setIsOpenNavMenuMobile] = useState<boolean>(false)
  const handleOpenNavbar = () => {
    setIsOpenNavMenuMobile(!isOpenNavMenuMobile)
  }
  const NavMenuBoxNode = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(NavMenuBoxNode, isOpenNavMenuMobile ? handleOpenNavbar : undefined)
  const handleSearch = (value: string) => {
    setSearchQuery(value)
    setShowSuggestions(true)
  }

  const handleShowSearchContainer = () => {
    setSearchMobileContainer(!showSearchMobileContainer)
  }

  const searchNav = (searchQuery: any) => (
    <Search ref={searchNode as any}>
      <SearchBar searchText={searchQuery} onSubmit={handleSearch} />
      {searchQuery.length > 0 && showSuggestions && (
        <SearchSuggestions
          searchQuery={searchQuery}
          close={() => {
            setSearchQuery('')
            setSearchMobileContainer(false)
          }}
          searchResults={searchResults}
          isLoading={isLoading || isFetching}
        />
      )}
    </Search>
  )

  return (
    <>
      <NavContainer visible={visible || searchQuery.length > 0 || showCollections || walletPopupOpen}>
        <Nav>
          <LogoDiv>
            <LinkRouter to="/" rel="noreferrer">
              <IconPortal SrcImageIcon={logo} widthIcon={'100%'} heightIcon={'25px'} />
            </LinkRouter>
          </LogoDiv>
          <SearchDesktop>{searchNav(searchQuery)}</SearchDesktop>

          <RowDiv>
            <NavMenuBox ref={NavMenuBoxNode}>
              <NavMenu open={isOpenNavMenuMobile}>
                <Dropdown ref={menuNode as any}>
                  <MenuLink
                    onClick={() => setShowCollections(!showCollections)}
                    className={showCollections ? 'active collection' : 'collection'}
                  >
                    collections
                    <ChevronDown />
                  </MenuLink>
                  {showCollections && (
                    <CollectionsMenu isOpen={showCollections} closeMenu={() => setShowCollections(false)} />
                  )}
                </Dropdown>

                <NavLink to={'/become-creator'} className={'nav-link-cs'}>
                  become a creator
                </NavLink>
              </NavMenu>
              <HamburgerBox onClick={handleOpenNavbar} className={isOpenNavMenuMobile ? 'open' : ''}>
                <div className={isOpenNavMenuMobile ? 'hamburger active' : 'hamburger'}>
                  <div className={'divEmpty'}></div>
                </div>
              </HamburgerBox>
            </NavMenuBox>

            <SearchMobileIcon onClick={handleShowSearchContainer}>
              <IconPortal SrcImageIcon={IconSearch} widthIcon={'100%'} heightIcon={'24px'} />
            </SearchMobileIcon>
            <HeaderControls>
              <HeaderElement>
                {provider && account && isValidChain ? (
                  <>
                    <AccountElement ref={accountNode as any} className={accountPopupOpen ? 'active' : ''}>
                      <AccountBtn onClick={toggleAccountPopup} className={accountPopupOpen ? 'active' : ''}>
                        <ProfileIcon />
                      </AccountBtn>
                      <AccountPopup />
                    </AccountElement>
                    <AccountElement ref={walletNode as any} className={walletPopupOpen ? 'active' : ''}>
                      <AccountBtn onClick={toggleWalletPopup} className={walletPopupOpen ? 'active' : ''}>
                        <WalletIcon />
                      </AccountBtn>
                      <WalletModal />
                    </AccountElement>
                  </>
                ) : (
                  <AccountElement ref={walletNode as any} className={walletPopupOpen ? 'active' : ''}>
                    <BtnConnect onClick={toggleWalletPopup} className={walletPopupOpen ? 'active' : ''}>
                      Connect
                    </BtnConnect>
                    <WalletModal />
                  </AccountElement>
                )}
              </HeaderElement>
            </HeaderControls>
          </RowDiv>
        </Nav>

        {showSearchMobileContainer && (
          <SearchMobileContainer ref={searchMobileNode as any}>{searchNav(searchQuery)}</SearchMobileContainer>
        )}
        <ClaimNFTButtonWrapper />
        {!isValidChain && (
          <WarningNetwork>
            You are connected to a network different than Polygon, make sure you change it to enable full functionality.
          </WarningNetwork>
        )}
      </NavContainer>
    </>
  )
}

const ClaimNFTButtonWrapper = styled(ClaimNFTButton)`
  text-transform: capitalize;
  padding: 7px;
  filter: drop-shadow(-2px 10px 12px #000000);
  font-weight: 600;
  font-size: ${({ theme }) => theme.fontSizeText2};
`

const SearchMobileContainer = styled.div`
  position: absolute;
  width: calc(100% - 30px);
  top: calc(100% + 20px); /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);

  @media (min-width: 1025px) {
    display: none;
  }
`

const SearchMobileIcon = styled.div`
  cursor: pointer;
  padding: 6px 10px;
  border: 2px solid #00301f;

  & img {
    height: 20px;
    width: 25px;
  }

  @media (min-width: 1025px) {
    display: none;
  }
`

const AccountBtn = styled(ButtonOutline)`
  ${({ theme }) => theme.flexRowNoWrap};
  height: 40px;
  border: 2px solid ${({ theme }) => theme.primary1};

  &:hover {
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.primary6};
  }
  &.active,
  :focus {
    border: 2px solid transparent;
    background-color: ${({ theme }) => theme.primary6};
    opacity: 1 !important;
    cursor: pointer !important;
    svg path {
      stroke: ${({ theme }) => theme.black};
    }
  }
`

const BtnConnect = styled(ButtonPrimary)`
  ${({ theme }) => theme.flexRowNoWrap}
  height: 40px;
  font-family: 'Din Pro Bold', sans-serif;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizeText2};
  line-height: 1.25;
  color: ${({ theme }) => theme.secondary1};
`
const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 1em;
  }

  /* addresses safari's lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 16px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: center;
  `};
`
const AccountElement = styled.div`
  display: inline-block;
  position: relative;
  flex-direction: row;
  align-items: center;
  white-space: normal;
  width: 100%;
  height: 42px;
  margin-top: -60px;
  padding-top: 25px;
  color: rgba(0, 255, 163, 0.16);
  font-family: 'Din Pro Regular', sans-serif;
  border-top: 4px solid transparent;
  &.active {
    border-top-color: ${({ theme }) => theme.primary6};
  }
  @media (max-width: 1024px) {
    height: 41px;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  margin-left: 15px;
`
const NavContainer = styled.div<{ visible?: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  background-color: ${({ theme }) => theme.black};
  width: 100%;
  position: relative;
  flex-direction: column;
`
const Nav = styled.div<Custom>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  padding: 0 60px;
  transition: transform 1s ease-out;
  margin: 0 auto;
  z-index: 10;

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`

const RowDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  @media (max-width: 575px) {
    flex: 1;
  }
`
const LogoDiv = styled.div`
  color: #7b7fda;
  text-decoration: none;
  height: 100%;

  @media (max-width: 1024px) {
    width: 140px;
  }

  @media (max-width: 575px) {
    flex: 1;
  }
  display: flex;
  align-items: center;
  margin-right: 2rem;
`
const Dropdown = styled.div`
  @media (max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const MenuLink = styled.a`
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 10px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: ${({ theme }) => theme.white};
  transition: all 0.3s ease-in;
  font-size: 0.9rem;
  font-family: 'Din Pro Regular', sans-serif;
  font-weight: 500;
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
    &.menu-link-pr {
      flex: 0 0 100%;
      max-width: 100%;
      border-top: 1px solid ${({ theme }) => theme.secondary2};
    }
    &.collection {
      display: flex;
      justify-content: space-between;
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  &:hover {
    color: ${({ theme }) => theme.primary6};
    svg {
      stroke: ${({ theme }) => theme.primary6};
    }
  }
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: ${({ theme }) => theme.secondary4};
  }
  &.active {
    color: ${({ theme }) => theme.primary4};
    svg {
      stroke: ${({ theme }) => theme.primary4};
      transform: rotate(180deg);
      -ms-transform: rotate(180deg);
    }
  }
`

const Search = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  flex: 0.8;

  @media (min-width: 1025px) {
    min-width: 350px;
  }
`

const SearchDesktop = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`
const NavMenuBox = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    order: 2;
    position: relative;
  }
`
const NavMenu = styled.div<{ open?: boolean }>`
  display: flex;
  align-items: center;
  .nav-link-cs {
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 10px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: ${({ theme }) => theme.white};
    transition: all 0.3s ease-in;
    font-size: 0.9rem;
    font-family: 'Din Pro Regular', sans-serif;
    font-weight: 500;
    &.active,
    &:hover {
      color: ${({ theme }) => theme.primary6};
    }
  }
  @media (max-width: 1024px) {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: ${({ theme }) => theme.secondary1};
    padding: 16px 32px;
    display: none;
    flex-wrap: wrap;
    width: 450px;
    margin-top: 12px;
    ${({ open }) =>
      open &&
      css`
        display: flex;
      `}
    .nav-link-cs {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: 575px) {
    width: calc(100vw - 40px);
  }
`
const HamburgerBox = styled.div`
  width: 40px;
  height: 40px;
  display: none;
  position: relative;
  border: 2px solid ${({ theme }) => theme.primary1};
  margin-left: 20px;
  @media (max-width: 1024px) {
    display: block;
    &.open {
      transition: 0.5s;
      background-color: ${({ theme }) => theme.primary6};
      border-color: ${({ theme }) => theme.primary6};
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: ${({ theme }) => theme.primary6};
        top: -32px;
        left: 0;
      }
    }
  }
  .hamburger {
    width: 20px;
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:before,
    &:after {
      content: '';
      background-color: ${({ theme }) => theme.primary6};
      display: block;
      height: 2px;
      border-radius: 3px;
      margin: 4px 0;
      transition: 0.5s;
    }
    &:after {
      width: 12px;
    }
    .divEmpty {
      background-color: ${({ theme }) => theme.primary6};
      display: block;
      height: 2px;
      border-radius: 3px;
      margin: 3px 0;
      transition: 0.5s;
      width: 16px;
    }
    &.active {
      &::before {
        transform: translateY(6px) rotate(135deg);
        background-color: ${({ theme }) => theme.secondary1};
      }
      &::after {
        transform: translateY(-6px) rotate(-135deg);
        background-color: ${({ theme }) => theme.secondary1};
        width: 100%;
      }
      & .divEmpty {
        transform: scale(0);
        background-color: ${({ theme }) => theme.secondary1};
        width: 100%;
      }
    }
  }
  @media (max-width: 768px) {
    margin-left: 10px;
  }
`
const WarningNetwork = styled.div`
  background-color: ${({ theme }) => theme.secondary3};
  font-size: ${({ theme }) => theme.fontSizeText3};
  line-height: 1.25;
  color: ${({ theme }) => theme.primary10};
  text-align: center;
  width: 100%;
  position: fixed;
  padding: 12px;
  top: 100px;
  left: 0;
  right: 0;
`

export default Navbar
